import deepPurple from "@material-ui/core/colors/deepPurple";
import styled, { css } from "styled-components";
import { createMuiTheme } from "@material-ui/core";
import { shade, tint } from "polished";

// used for mixing theme colors, not meant for export
const base = {
  orange: "#F26148",
  purple: "#53339C"
};

// App Theme
const Theme = {
  // colors
  orange: base.orange,
  orangeHover: shade(0.1, base.orange),
  orangeGhost: tint(0.9, base.orange),
  purple: base.purple,
  purpleHover: shade(0.1, base.purple),
  cardPurple: "#462B80",
  lightBlue: "#B8DFF5",

  textLight: "#BDBDBD",
  textMedium: "#8E8E93",
  textNormal: "#4F4F4F",
  textDark: "#2F1D57",

  uiWhite: "#FFFFFF",
  uiLighter: "#E0E0E0",
  windowBackground: "#F5F5F5",

  // fonts
  moderat: `
    'Moderat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol'
  `,
  simplonNorm: `
    'Simplon Norm',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol'
  `,
  openSans: `
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol'
  `,

  // font weights

  // defaults
  transition: "all 150ms ease-in-out",

  // easings
  easeOutBack: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
  pop: "cubic-bezier(.58,.35,.05,1.71)",

  // gradients
  gradients: {
    purple: "linear-gradient(180deg, #7C53D9 0%, #53339C 100%)"
  },

  // overlays

  // sizes
  containerWidth: "1040px",

  // breakpoints
  breakpoints: {
    xs: "480px",
    sm: "600px",
    md: "960px",
    lg: "1280px",
    xl: "1920px"
  },

  // mixins
  smallText: css`
    text-transform: uppercase;
    font-size: 11px;
    line-height: 13px;
    font-family: ${() => Theme.simplonNorm};
    font-weight: 500;
    letter-spacing: 0.75px;
  `,
  fullBleedChild: css`
    width: calc(100% + 32px);
    position: relative;
    left: -16px;

    @media (min-width: ${() => Theme.breakpoints.sm}) {
      width: 100%;
      position: static;
    }
  `
};
export default Theme;

// Material UI Theme
export const muiTheme = createMuiTheme({
  palette: {
    primary: deepPurple
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: Theme.purple
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontSize: 16,
        "&.MuiTypography-h4": {
          fontSize: 30
        }
      }
    },
    MuiPickersYear: {
      root: {
        fontSize: 14
      },
      yearSelected: {
        fontSize: 24
      }
    },
    MuiButton: {
      root: {
        fontSize: 12
      }
    },
    MuiFormLabel: {
      root: {
        textTransform: "uppercase",
        fontFamily: Theme.simplonNorm,
        letterSpacing: "1px",
        color: Theme.textMedium,
        "& .Mui-focused": {
          color: Theme.textMedium
        }
      }
    },
    MuiInput: {
      input: {
        "&::placeholder": {
          color: Theme.textLight,
          opacity: 0.75
        }
      },
      root: {
        width: "100%",
        fontFamily: Theme.moderat,
        fontSize: "16px",
        lineHeight: "19px",
        color: Theme.textNormal,
        "&:before": {
          borderBottom: `1px solid ${Theme.uiLighter}`
        },
        "&:hover:before": {
          borderBottom: `1px solid ${Theme.purple} !important`
        },
        "&:after": {
          borderBottom: `1px solid ${Theme.purple} !important`
        },
        "& .Mui-focused": {
          "&:before": {
            borderBottom: `1px solid ${Theme.purple} !important`
          },
          "&:after": {
            borderBottom: `1px solid ${Theme.purple} !important`
          }
        }
      }
    },
    MuiFormHelperText: {
      root: {
        "&$error": {
          color: Theme.orange,
          margin: "5px 0 0",
          fontSize: "12px",
          lineHeight: "16px"
        }
      }
    }
  }
});

export const fadeInStyles = css`
  animation: 300ms ease-in-out fadein forwards;
  @keyframes fadein {
    from {
      transform: translateX(20px);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;
export const FadeIn = styled.div`
  ${fadeInStyles}
`;
export const fadeOutStyles = css`
  animation: 300ms ease-in-out fadeout forwards;
  @keyframes fadeout {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100px);
      opacity: 0;
    }
  }
`;
