import React from "react";
import t from "prop-types";
import styled from "styled-components";
import Card from "./Card";
import logo from "../static/images/card-logo.png";
import Theme from "../styles/Theme";

ClutchCard.propTypes = {
  memberId: t.string.isRequired,
  rxBin: t.string.isRequired,
  rxGroup: t.string.isRequired,
  rxPcn: t.string.isRequired
};

export default function ClutchCard({ memberId, rxBin, rxGroup, rxPcn }) {
  return (
    <StyledCard>
      <Header>
        <img src={logo} alt="Clutch Card Logo"></img>
        <h4>Clutch Card</h4>
      </Header>
      <main>
        <Label>Member ID</Label>
        <ID>{memberId}</ID>
      </main>
      <Details>
        <Detail>
          <Label>Bin</Label>
          <span>{rxBin}</span>
        </Detail>
        <Detail>
          <Label>PCN</Label>
          <span>{rxPcn}</span>
        </Detail>
        <Detail>
          <Label>Group</Label>
          <span>{rxGroup}</span>
        </Detail>
      </Details>
    </StyledCard>
  );
}

// style
const StyledCard = styled(Card)`
  width: 100%;
  max-width: 345px;
  padding: 14px 20px 35px;
  background: ${Theme.cardPurple};
  color: ${Theme.uiWhite};
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);

  img {
    height: 40px;
    margin-right: 14px;
  }
  h4 {
    color: ${Theme.uiWhite};
    margin-bottom: 0;
    font-size: 19px;
    line-height: 1;
    letter-spacing: 0.8px;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 33px;
`;

const Label = styled.label`
  ${Theme.smallText}
  display: block;
  opacity: 0.5;
  margin-bottom: 4px;
`;

const ID = styled.span`
  font-size: 26px;
  letter-spacing: 2px;
`;

const Details = styled.section`
  margin-top: 30px;
  padding-right: 36px;
  display: flex;
  justify-content: space-between;
`;

const Detail = styled.div`
  label {
    margin-bottom: 6px;
  }
  span {
    ${Theme.smallText}
  }
`;
