import React, { useEffect } from "react";
import get from "lodash.get";
import gql from "graphql-tag";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import AppChrome from "./components/AppChrome";
import AccountPage from "./pages/AccountPage";
import CreateAccountPage from "./pages/CreateAccountPage";
import DashboardPage from "./pages/DashboardPage";
import FlashNotifier from "./components/FlashNotifier";
import GlobalFonts from "./static/fonts/fonts";
import GlobalStyles from "./styles/GlobalStyle";
import LoginPage from "./pages/LoginPage";
import ModernNormalize from "./styles/ModernNormalize";
import MustUpdatePasswordPage from "./pages/MustUpdatePasswordPage";
import MyClutchPage from "./pages/MyClutchPage";
import PageNotFound from "./pages/PageNotFound";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import VerifyPhonePage from "./pages/VerifyPhonePage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import { useSilentAuth } from "./utils/useSilentAuth";

const GET_AUTH_STATUS = gql`
  query GetAuthStatus {
    isLoggedIn @client
  }
`;

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      phone
      flags {
        mustVerifyPhone
        mustUpdatePassword
      }
    }
  }
`;

export default function App() {
  // auth
  useSilentAuth();
  const {
    data: { isLoggedIn }
  } = useQuery(GET_AUTH_STATUS);

  // user data
  const [getUserData, { data }] = useLazyQuery(CURRENT_USER);
  useEffect(() => {
    if (isLoggedIn) getUserData();
  }, [isLoggedIn, getUserData]);

  // flags
  const mustVerifyPhone = get(data, "currentUser.flags.mustVerifyPhone");
  const mustUpdatePassword = get(data, "currentUser.flags.mustUpdatePassword");

  // render
  return (
    <Router>
      <ModernNormalize />
      <GlobalFonts />
      <GlobalStyles />
      <FlashNotifier />

      {mustVerifyPhone && (
        <>
          <VerifyPhonePage />
          {!isLoggedIn && <Redirect to="/login" />}
        </>
      )}
      {mustUpdatePassword && (
        <>
          <MustUpdatePasswordPage />
          {!isLoggedIn && <Redirect to="/login" />}
        </>
      )}

      {!mustVerifyPhone && !mustUpdatePassword && (
        <Switch>
          <LoggedInRoute path="/" exact>
            <DashboardPage />
          </LoggedInRoute>
          <LoggedInRoute path="/my-clutch">
            <MyClutchPage />
          </LoggedInRoute>
          <LoggedInRoute path="/account" exact>
            <AccountPage />
          </LoggedInRoute>
          <LoggedInRoute path="/terms-of-use" exact>
            <TermsOfUsePage />
          </LoggedInRoute>
          <LoggedInRoute path="/privacy-policy" exact>
            <PrivacyPolicyPage />
          </LoggedInRoute>

          <LoggedOutRoute path="/create-account" exact>
            <CreateAccountPage />
          </LoggedOutRoute>
          <LoggedOutRoute path="/login" exact>
            <LoginPage />
          </LoggedOutRoute>
          <LoggedOutRoute path="/forgot-password" exact>
            <ForgotPasswordPage />
          </LoggedOutRoute>

          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      )}
    </Router>
  );
}

const LoggedInRoute = ({ children, ...rest }) => {
  const {
    data: { isLoggedIn }
  } = useQuery(GET_AUTH_STATUS);
  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? (
          <AppChrome>{children}</AppChrome>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const LoggedOutRoute = ({ children, ...rest }) => {
  const {
    data: { isLoggedIn }
  } = useQuery(GET_AUTH_STATUS);
  return (
    <Route
      {...rest}
      render={() => (isLoggedIn === false ? children : <Redirect to="/" />)}
    />
  );
};
