import React from "react";
import t from "prop-types";
import { Link } from "react-router-dom";
import Card from "../Card";
import ArticleCard from "./ArticleCard";
import VideoCard from "./VideoCard";
import Theme from "../../styles/Theme";
import styled from "styled-components";

Resources.propTypes = {
  items: t.array.isRequired
};

export default function Resources({ items }) {
  return (
    <Wrapper>
      <Heading>Resources</Heading>
      <Grid>
        {items.map(function(item, index) {
          if (item.category === "video") {
            return (
              <VideoCard
                key={index}
                title={item.title}
                subtitle="Getting Started"
                bgImage={item.imageUrl}
                videoUrl={item.url}
              />
            );
          } else if (item.category === "article") {
            return (
              <ArticleCard
                key={index}
                title={item.title}
                subtitle="Clutch Review"
                bgImage={item.imageUrl}
                link={item.url}
              />
            );
          }
          return false;
        })}
      </Grid>
      <Grid>
        <Card>
          <h4 style={{ marginBottom: 15 }}>Other Helpful Links</h4>
          <div>
            <LinkItem>
              <Link to="/terms-of-use">Terms of Use</Link>
            </LinkItem>
            <LinkItem>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </LinkItem>
          </div>
        </Card>
        <Card>
          <h4 style={{ marginBottom: 15 }}>Issues with the App?</h4>
          <div>
            <ContactLink>
              Email us
              <a href="mailto:contact@clutch.health">contact@clutch.health</a>
            </ContactLink>
            <ContactLink>
              Call us
              <a href="tel:1800252284498524">1-800-CLUTCHHEALTH</a>
            </ContactLink>
          </div>
        </Card>
      </Grid>
    </Wrapper>
  );
}
// styles
const Wrapper = styled.div`
  max-width: ${Theme.containerWidth};
  @media (min-width: ${Theme.breakpoints.md}) {
    border-left: 1px solid ${Theme.windowBackground};
    padding-left: 38px;
    flex-grow: 1;
  }
`;
export const Grid = styled.div`
  display: grid;
  grid-gap: 30px 28px;
  grid-template-columns: 1fr;
  margin-bottom: 28px;

  @media (min-width: ${Theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Heading = styled.h4`
  margin-bottom: 19px;
  display: none;

  @media (min-width: ${Theme.breakpoints.md}) {
    display: block;
  }
`;
const LinkItem = styled.li`
  line-height: 24px;
  list-style-type: none;
  &:before {
    content: "• ";
    padding-right: 3px;
  }
`;
const ContactLink = styled.li`
  line-height: 24px;
  margin-bottom: 6px;
  list-style-type: none;
  &:before {
    content: "• ";
    padding-right: 3px;
  }

  a {
    color: ${Theme.orange};
    font-weight: bold;
    padding-left: 10px;
    display: block;
    &:hover,
    &:focus {
      color: ${Theme.orangeHover};
    }
  }
`;
