import React from "react";
import MaskedInput from "react-text-mask";
import t from "prop-types";
import Input from "./Input";

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

PhoneField.propTypes = {
  label: t.string.isRequired,
  name: t.string.isRequired,
  onChange: t.func.isRequired,
  hasError: t.bool,
  onBlur: t.func,
  placeholder: t.string,
  value: t.string
};

export default function PhoneField(props) {
  return <Input {...props} inputComponent={TextMaskCustom} />;
}

TextMaskCustom.propTypes = {
  inputRef: t.func.isRequired
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      guide={false}
    />
  );
}
