import React from "react";
import styled from "styled-components";
import t from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";
import PrescriptionStatus from "../PrescriptionStatus";
import Theme from "../../styles/Theme";

// prop types
PrescriptionListMobile.propTypes = {
  prescriptions: t.arrayOf(
    t.shape({
      id: t.string.isRequired,
      doseForm: t.string,
      doseStrength: t.string,
      quantity: t.number.isRequired,
      drugName: t.string.isRequired,
      genericDrugName: t.string,
      new: t.bool,
      state: t.oneOf(["unprocessed", "transferred", "filled", "reversed"]),
      instructions: t.string.isRequired,
      refillsRemaining: t.number.isRequired,
      doctor: t.shape({
        id: t.string.isRequired,
        firstName: t.string.isRequired,
        lastName: t.string.isRequired,
        phone: t.string
      }).isRequired
    })
  ).isRequired
};

export default function PrescriptionListMobile({ prescriptions }) {
  let { url } = useRouteMatch();
  const unfilled = prescriptions.filter(item => item.state === "unprocessed");
  const sent = prescriptions.filter(item => item.state === "transferred");
  const filled = prescriptions.filter(item => item.state === "filled");
  const reversed = prescriptions.filter(item => item.state === "reversed");

  return (
    <>
      <Label>Unfilled Prescriptions</Label>
      {unfilled.map(function(item, index) {
        return (
          <Item key={index}>
            <div>
              <Link to={`${url}/${item.id}-${encodeURI(item.drugName)}`}>
                <PrescriptionLink>{item.drugName}</PrescriptionLink>
              </Link>
              <Info>
                Dr. {item.doctor.firstName} {item.doctor.lastName} |{" "}
                {item.doseStrength} / {item.quantity} {item.doseForm}
              </Info>
            </div>
            <PrescriptionStatus text=" " status={item.state} />
          </Item>
        );
      })}
      <Label>Sent to Pharmacy</Label>
      {sent.map(function(item, index) {
        return (
          <Item key={index}>
            <div>
              <Link to={`${url}/${item.id}-${encodeURI(item.drugName)}`}>
                <PrescriptionLink>{item.drugName}</PrescriptionLink>
              </Link>
              <Info>
                Dr. {item.doctor.firstName} {item.doctor.lastName} |{" "}
                {item.doseStrength} / {item.quantity} {item.doseForm}
              </Info>
            </div>
            <PrescriptionStatus text=" " status={item.state} />
          </Item>
        );
      })}
      <Label>Filled Prescriptions</Label>
      {filled.map(function(item, index) {
        return (
          <Item key={index}>
            <div>
              <Link to={`${url}/${item.id}-${encodeURI(item.drugName)}`}>
                <PrescriptionLink>{item.drugName}</PrescriptionLink>
              </Link>
              <Info>
                Dr. {item.doctor.firstName} {item.doctor.lastName} |{" "}
                {item.doseStrength} / {item.quantity} {item.doseForm}
              </Info>
            </div>
            <PrescriptionStatus text=" " status={item.state} />
          </Item>
        );
      })}
      <Label>Reversed Prescriptions</Label>
      {reversed.map(function(item, index) {
        return (
          <Item key={index}>
            <div>
              <Link to={`${url}/${item.id}-${encodeURI(item.drugName)}`}>
                <PrescriptionLink>{item.drugName}</PrescriptionLink>
              </Link>
              <Info>
                Dr. {item.doctor.firstName} {item.doctor.lastName} |{" "}
                {item.doseStrength} / {item.quantity} {item.doseForm}
              </Info>
            </div>
            <PrescriptionStatus text=" " status={item.state} />
          </Item>
        );
      })}
    </>
  );
}

// style
const Label = styled.div`
  width: 100vw;
  transform: translateX(-16px);
  background: ${Theme.windowBackground};
  ${Theme.smallText}
  padding: 12px 16px;
`;
const Item = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;
const PrescriptionLink = styled.h3`
  cursor: pointer;
`;
const Info = styled.span``;
