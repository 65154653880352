import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Theme from "../styles/Theme";

// prop types
Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default function Card({ children, className, ...props }) {
  return (
    <StyledCard className={className} {...props}>
      {children}
    </StyledCard>
  );
}

// style
const StyledCard = styled.div`
  background: ${Theme.uiWhite};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  padding: 18px;
`;
