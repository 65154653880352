import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../static/images/clutch-logo.svg";
import { Wrapper, Header, Logo } from "./CreateAccountPage";

export default function PageNotFound() {
  return (
    <Wrapper>
      <Header>
        <Logo src={logo} alt="logo" />
      </Header>

      <Flex>
        <Large>404</Large>
        <Right>
          <Heading>Oops!</Heading>
          <p>We can&apos;t seem to find the page you&apos;re looking for...</p>
          <StyledLink style={{ color: "white" }} to="/">
            Return to Home
          </StyledLink>
        </Right>
      </Flex>
    </Wrapper>
  );
}

// styles
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: white;
  margin-top: 96px;
`;
const Large = styled.h1`
  color: white;
  font-size: 200px;
  line-height: 160px;
  font-weight: bold;
  padding: 0 21px;
`;
const Heading = styled.h3`
  color: white;
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
`;
const Right = styled.div`
  padding: 0 21px;
  max-width: 459px;
  font-size: 30px;
  line-height: 36px;

  p {
    opacity: 0.8;
    margin: 10px 0;
  }
`;
const StyledLink = styled(Link)`
  font-size: 24px;
  line-height: 29px;
  text-decoration: underline;
`;
