import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Theme from "../styles/Theme";
import iconSort from "../static/icons/icon-sort.svg";

export default function TermsOfUsePage() {
  return (
    <>
      <Link to="/">
        <LinkText>
          <IconBack src={iconSort} /> Dashboard
        </LinkText>
      </Link>
      <h1>Terms of Use</h1>

      <div>
        <p>
          These Terms of Use (the “Terms of Use” or the “Agreement”) describe
          the terms on which you may access and use the prescription drug access
          program (the “Program” or “Services”) and related products and
          services (the “Services”) offered through the mobile app that links to
          these Terms of Use (the “Website”), which is owned and operated by
          Clutch Holdings LLC (“Clutch”). BY CLICKING YOUR ACCEPTANCE OF THESE
          TERMS OF USE, YOU SIGNIFY THAT YOU HAVE READ, UNDERSTAND AND AGREE TO
          BE BOUND BY THIS AGREEMENT. Your continued use of the Services
          following the posting of any changes to the Terms of Use constitutes
          acceptance of those changes. In the event of any inconsistency between
          the Clutch Privacy Policy and this Agreement, this Agreement shall
          control.
        </p>
        <p>
          We may revise the Terms of Use from time to time by updating this
          posting, with the new terms taking effect on the date of posting. You
          should review these Terms of Use every time you use the Services
          because they are binding on you. IF YOU DO NOT AGREE TO THE TERMS AND
          CONDITIONS SET FORTH HERE, PLEASE DO NOT USE THE WEBSITE OR THE MOBILE
          APP.
        </p>
        <h3>CLUTCH IS NOT INSURANCE</h3>
        <p>
          CLUTCH IS A MEMBERSHIP ORGANIZATION THAT CONTRACTS WITH PHARMACIES AND
          OTHERS TO PROVIDE PATIENTS WITH ACCESS TO AFFORDABLE MEDICATIONS. AS
          DESCRIBED IN THESE TERMS OF USE, YOUR CLUTCH MEMBERSHIP ENTITLES YOU
          TO ACCESS TO AFFORDABLE MEDICATIONS. CLUTCH IS NOT AN INSURANCE PLAN,
          AND IS NOT INTENDED AS A SUBSTITUTE FOR INSURANCE. THE CLUTCH
          PRESCRIPTION DRUG ACCESS PROGRAM CANNOT BE USED IN CONJUNCTION WITH
          (1) ANY FEDERAL OR STATE-FUNDED PROGRAM SUCH AS MEDICARE OR MEDICAID
          OR (2) ANY HEALTHCARE COVERAGE OR BENEFIT. THE CLUTCH PRESCRIPTION
          DRUG ACCESS PROGRAM ALSO DOES NOT MEET MINIMUM CREDITABLE COVERAGE
          REQUIREMENTS UNDER STATE HEALTHCARE COVERAGE REGULATIONS. You have the
          right to cancel your access to the Services at any time as described
          in the “Termination” section. You may request a written list of
          pharmacies participating in the Program in your area, including name,
          address and phone number, by contacting us as indicated in the
          “Notices” section. Clutch does not make any payments to pharmacies to
          participate in the Program.
        </p>
        <p>
          Please note that cash payments that you make for medications through
          the Services may not count toward your prescription drug coverage out
          of pocket costs, but you can confirm that by contacting your coverage
          provider. If you are a Medicare Part D beneficiary, then cash payments
          made through the Services will not count toward your Medicare Part D
          cost-sharing obligation.
        </p>
        <h3>Availability</h3>
        <p>Clutch is available for use in 50 states.</p>
        <h3>Prescription Drug Pricing and Charges</h3>
        <p>
          Clutch reserves the right to change its prescription drug prices in
          real time based on supply and demand trends, the location of the
          customer, the customer's historical purchases and any other factors
          that may have an impact on Clutch's cost structure. Clutch will always
          honor the price reflected at the time that the customer purchases a
          medication with the following limited exception. Clutch reserves the
          right to cancel a transaction and refund you if the underlying cost of
          the prescription drug changes significantly after you have purchased
          your medication.
        </p>
        <p>
          For new purchases, we pre-authorize payment for the dosage and
          quantity that you have selected -- it’s fully refundable. Your final
          price is based on the medication you pick up at the pharmacy. If you
          pick up a different form, dosage, or quantity, you will be charged or
          credited the difference. If you redeem a prescription at a pharmacy
          with which Clutch does not have a contract, you will be automatically
          charged the difference in price between the Clutch price and the
          Pharmacy’s price.
        </p>
        <h3>Appointment of Clutch as Your Agent</h3>
        <p>
          Clutch provides healthcare management services designed to reduce the
          cost of your healthcare and facilitate your healthcare-related
          interactions with physicians, pharmacies, healthcare coverage
          providers and pharmacy benefit managers (“PBMs”) (collectively,
          “Healthcare Providers”). In order to provide you with these services,
          Clutch needs you to authorize Clutch to take certain actions on your
          behalf. BY ACCEPTING THESE TERMS OF USE, YOU APPOINT CLUTCH AS YOUR
          AGENT WITH RESPECT TO (I) OBTAINING YOUR MEDICAL INFORMATION,
          INCLUDING PROTECTED HEALTH INFORMATION (“PHI”) SUBJECT TO THE HEALTH
          INSURANCE PORTABILITY AND ACCOUNTABILITY ACT (“HIPAA”), FROM YOUR
          HEALTHCARE PROVIDERS, INCLUDING EXERCISING YOUR RIGHT TO OBTAIN ACCESS
          TO YOUR PHI PURSUANT TO 45 C.F.R. § 164.524 ON ONE OR MORE OCCASIONS
          WHILE YOU ARE A CLUTCH MEMBER, AND (II) CONTACTING HEALTHCARE
          PROVIDERS, INCLUDING PHYSICIANS OR PHARMACIES, ON YOUR BEHALF TO
          REQUEST INFORMATION NECESSARY TO PROVIDE CLUTCH’S SERVICES, INCLUDING,
          BUT NOT LIMITED TO, YOUR MEDICAL AND HEALTHCARE COVERAGE INFORMATION,
          YOUR MEDICATION HISTORY, AND YOUR BILLING INFORMATION AND HISTORY.
          Examples of communications that Clutch may make on your behalf as your
          agent include contacting your pharmacy to (i) request transfer of your
          prescription to a new pharmacy, (ii) initiate a prescription refill or
          auto-refill or (iii) request that the pharmacy seek a prescription
          renewal authorization from your physician. Clutch may also contact
          your physician so that the physician may contact you regarding a
          refill renewal.
        </p>
        <p>
          Clutch will be authorized to act as your agent solely for the purpose
          of providing the Services outlined in these Terms of Use. Your
          appointment of Clutch as your agent will end if this Agreement is
          terminated as described in the “Termination” section.
        </p>
        <h3>Use of Promotions and Clutch Memberships</h3>
        <p>
          You may only create one Clutch membership for your personal use;
          creation of multiple Clutch memberships by a single user is a breach
          of these Terms of Use with the following limited exception: You may
          create a membership for a dependent or other person for whom you have
          the authority to purchase and pickup medications, however, you will be
          responsible for compliance with these Terms of Use. Clutch may offer
          promotions from time to time, such as programs that offer you rewards
          for referring new users to Clutch or filling your first prescription
          through Clutch (“Clutch Promotions”). Referral promotion codes are
          intended for an invited user’s first prescription filled through
          Clutch – not for existing Clutch users. First-prescription promotions
          may only be used once by an individual user – you may not create
          multiple Clutch memberships and use a first-prescription promotion
          code more than once. A code issued to you to participate in a Clutch
          Promotion should only be used for personal and non-commercial
          purposes. This means that you can invite your friends to become Clutch
          members as part of a Clutch referral promotion using your personal
          connections via social media where you are the primary content owner.
          Public distribution of a Clutch referral promotion on sites where you
          are a contributor but not the primary content owner (e.g., Wikipedia,
          coupon websites) is not allowed. Promoting your referral code via
          Search Engine Marketing (e.g., AdWords) is also not allowed. Clutch
          reserves the right to suspend your account and revoke any referral
          credits if they were earned in violation of these Terms of Use. If you
          obtain a first-prescription offer in violation of these Terms of Use,
          Clutch reserves the right to charge the credit card that you have
          provided for the amount of any unauthorized first-prescription
          offer(s).
        </p>
        <h3>Your Healthcare Choices</h3>
        <p>
          As described above, Clutch acts as your agent in facilitating
          healthcare-related interactions with your Healthcare Providers, but
          only to the extent that those interactions are initiated by you. You
          remain responsible for your healthcare choices and decisions. The
          Services are designed to offer you with additional choices and options
          regarding your healthcare, not limit those choices and options. You
          may always choose to obtain healthcare products and services through
          channels other than the Services and from Healthcare Providers that do
          not have arrangements with Clutch.
        </p>
        <h3>Information</h3>
        <p>
          The information presented in the App is intended and presented only
          for general educational purposes and is used solely at the risk of the
          user; is not intended as, and may not be used as, a substitute for the
          diagnosis, expertise, treatment, advice, and judgment of a physician,
          nurse, pharmacist or other healthcare professional (a health care
          professional should be consulted before taking or discontinuing a drug
          mentioned in the information presented or before relying on the
          information); is subject to change without notice; is not
          comprehensive and does not cover all uses, precautions, side effects,
          warnings, and interactions related to pharmaceuticals or treatments;
          may not apply to any specific medical condition, does not take into
          account personal circumstances and should not be relied upon or
          construed to indicate that a use of a drug or treatment is safe,
          appropriate, or effective for a given individual; is only applicable
          to use in the United States and its territories; does not constitute
          medical advice or professional advice of any kind; is offered "as is"
          with no warranty or guarantee of accuracy, completeness, or
          suitability for use by any individual; and has not been reviewed for
          compliance with federal or state pharmaceutical marketing,
          advertising, and disclosure statures or regulations.
        </p>
        <h3>Termination</h3>
        <p>
          Clutch reserves the right, in its sole discretion, to terminate your
          access to all or part of the Services, with or without notice.
          Examples of activity that may lead to a termination of your use of the
          Services include your breach of any of these Terms of Use. You may
          terminate this Agreement by providing Clutch with ten (10) days
          written notice of such termination by contacting Clutch at
          info@clutch.health.
        </p>
        <h3>Disclosure of Financial Relationships</h3>
        <p>
          Clutch may at times receive compensation from its pharmaceutical
          manufacturer partners for providing marketing and adherence services.
        </p>
        <h3>No Practice of Medicine</h3>
        <p>
          The Services are not intended to be, and must not be taken to be, the
          practice of medicine or the practice of other healthcare services by
          Clutch. Use of the Mobile App or the Services does not create a
          physician/patient or provider/patient relationship with Clutch. Clutch
          is not recommending or endorsing any specific prescription drug,
          pharmacy or other information. Please seek medical advice before
          starting, changing or terminating any medication or prescription.
        </p>
        <h3>Proprietary Rights in Mobile App Content</h3>
        <p>
          Clutch retains all copyright and other proprietary rights in the
          contents of this Mobile App and the Services (the “Content”). Elements
          of the Content are protected by copyright, trade dress and other laws,
          and may not be copied or imitated in whole or in part. Nothing shall
          be construed as granting you any license under any patent, trademark
          or copyright of Clutch or any third party. Certain portions of the
          Services contain information supplied and updated by third parties, or
          include links to third-party sites. Clutch is not responsible for, and
          makes no warranty as to the accuracy of, such information or sites.
        </p>
        <p>
          Clutch claims no ownership in, nor any affiliation with, third-party
          trademarks or brand names that may appear on this site. Such
          third-party trademarks are used only to identify the products and
          services of their respective owners, and no sponsorship or endorsement
          on the part of Clutch should be inferred from their use. You are not
          permitted to use the trademarks displayed on the Services without the
          prior written consent of Clutch or the third party that may own the
          trademarks.{" "}
        </p>
        <p>
          No Content may be modified, copied, distributed, framed, reproduced,
          republished, downloaded, displayed, posted, transmitted or sold in any
          form or by any means, in whole or in part, without Clutch’s prior
          written permission. You may download or print a copy of any portion of
          the Content solely for your personal, non-commercial use, provided
          that you keep all copyright or other proprietary notices intact. You
          may not republish Content contained in the Services or incorporate the
          information in any other database or compilation. Any other use of the
          Content is strictly prohibited.
        </p>
        <h3>Links to Other Mobile Apps or Websites</h3>
        <p>
          The Services may contain links to other mobile apps or websites. We
          are not responsible for the content, accuracy or opinions expressed in
          such mobile apps or websites, and such mobile apps or websites are not
          investigated, monitored or checked for accuracy or completeness by us.
          Inclusion of any linked mobile app or website on or through the
          Services does not imply approval or endorsement of the linked mobile
          app or website by us. If you decide to leave this Mobile App or the
          Services and access these third-party mobile apps or websites, you do
          so at your own risk.
        </p>
        <h3>Text Messaging and Telephone Calls</h3>
        <p>
          You agree that Clutch may contact you by telephone or text messages
          (including by an automatic telephone dialing system) at any of the
          phone numbers provided by you or on your behalf in connection with a
          Clutch account. Message and data rates may apply. You understand that
          your mobile phone service provider may charge you fees for calls made
          or texts sent to you, and you agree that we will have no liability for
          the cost of any such calls or texts.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          Clutch, its affiliates and any of its, or their, directors, officers,
          employees or agents shall not, under any circumstances, be liable for
          direct, consequential, incidental, indirect or special damages of any
          kind, or any other damages whatsoever, including, without limitation,
          those resulting from loss of use, data or profits, and whether
          resulting from the use or inability of use of any contents of the
          Services (or a mobile app or website linked to the Services), or any
          other cause, even if such cause involves negligence, or if we have
          been apprised of the likelihood of such damages occurring.
        </p>
        <p>
          The above limitation, or exclusion, may not apply to you to the extent
          that applicable law prohibits the limitation or exclusion of liability
          for incidental or consequential damages.
        </p>
        <h3>Disclaimer of Warranties</h3>
        <p>
          YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED ON AN “AS IS”
          AND “AS AVAILABLE” BASIS AND THAT CLUTCH DOES NOT ASSUME ANY
          RESPONSIBILITY FOR PROMPT OR PROPER DELIVERY, OR RETENTION OF ANY
          PERSONAL INFORMATION. CLUTCH EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY
          KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE AND NON-INFRINGEMENT.
        </p>
        <p>
          CLUTCH MAKES NO WARRANTY THAT (1) THE SERVICES WILL MEET YOUR
          REQUIREMENTS, (2) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE
          OR ERROR-FREE, (3) THE QUALITY OF ANY INFORMATION, INCLUDING PRICING
          INFORMATION, OR OTHER MATERIAL OBTAINED BY YOU THROUGH THE SERVICES
          WILL MEET YOUR EXPECTATIONS, AND (4) ANY ERRORS IN THE SOFTWARE WILL
          BE CORRECTED.{" "}
        </p>
        <p>
          ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
          SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
          SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
          DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.{" "}
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM CLUTCH OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY
          NOT EXPRESSLY STATED IN THESE TERMS OF USE.
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES.
          ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        </p>
        <h3>Dispute Resolution</h3>
        <p>
          Any controversy or claim between the parties or arising out of these
          Terms of Use or any use of the Mobile App or the Services shall be
          determined by one disinterested arbitrator in binding arbitration
          pursuant to the Commercial Arbitration Rules and the Supplementary
          Procedures for Online Arbitration of the American Arbitration
          Association (the “AAA”). The arbitrator shall be experienced in
          agreements for information technology services, either as an attorney
          or as an information technology professional. If the parties fail to
          appoint an arbitrator within forty-five (45) days of the institution
          of the arbitration, the AAA shall select the arbitrator promptly
          thereafter. Any requests for accelerated, emergency or preliminary
          relief shall be submitted pursuant to the AAA’s Optional Rules for
          Emergency Measures of Protection. If any such requests are made before
          an arbitration panel is empaneled, then the AAA shall appoint one
          disinterested arbitrator as an arbitration panel to immediately hear
          and decide such request. The arbitration panel shall have the right to
          grant interim awards. Testimony shall be permitted by telephone, video
          conference and other forms of real-time telecommunications. If the
          arbitrator requires in-person hearings, the hearings shall be held in
          Atlanta, Georgia. The arbitral award will be final and binding, and
          may be entered and enforced in any court of competent jurisdiction.
        </p>
        <h3>Waiver of Jury Trial and Class Actions</h3>
        <p>
          BY ENTERING INTO THESE TERMS OF USE, YOU AND CLUTCH ACKNOWLEDGE AND
          AGREE TO WAIVE CERTAIN RIGHTS TO LITIGATE DISPUTES IN COURT, TO
          RECEIVE A JURY TRIAL OR TO PARTICIPATE AS A PLAINTIFF OR AS A CLASS
          MEMBER IN ANY CLAIM ON A CLASS OR CONSOLIDATED BASIS OR IN A
          REPRESENTATIVE CAPACITY. YOU AND CLUTCH BOTH AGREE THAT ANY
          ARBITRATION WILL BE CONDUCTED ON AN INDIVIDUAL BASIS AND NOT A
          CONSOLIDATED, CLASS-WIDE OR REPRESENTATIVE BASIS AND THE ARBITRATOR
          SHALL HAVE NO AUTHORITY TO PROCEED WITH AN ARBITRATION ON A CLASS OR
          REPRESENTATIVE BASIS. THE ARBITRATOR MAY AWARD INJUNCTIVE RELIEF ONLY
          IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
          NECESSARY TO PROVIDE RELIEF WARRANTED BY THAT PARTY’S INDIVIDUAL
          CLAIM. IF FOR ANY REASON THE ARBITRATION CLAUSE SET FORTH IN THESE
          TERMS OF USE IS DEEMED INAPPLICABLE OR INVALID, OR TO THE EXTENT THE
          ARBITRATION CLAUSE ALLOWS FOR LITIGATION OF DISPUTES IN COURT, YOU AND
          CLUTCH BOTH WAIVE, TO THE FULLEST EXTENT ALLOWED BY LAW, ANY RIGHT TO
          PURSUE OR TO PARTICIPATE AS A PLAINTIFF OR AS A CLASS MEMBER IN ANY
          CLAIM ON A CLASS OR CONSOLIDATED BASIS OR IN A REPRESENTATIVE
          CAPACITY.
        </p>
        <h3>Indemnification</h3>
        <p>
          By accepting these Terms of Use, you agree to indemnify and otherwise
          hold harmless Clutch and its officers, employees, agents,
          subsidiaries, affiliates, licensors, suppliers and other partners from
          any direct, indirect, incidental, special, consequential or exemplary
          damages resulting from your use of the Services. YOU AGREE TO NOTIFY
          CLUTCH IF YOU STOP USING A PHONE NUMBER THAT YOU HAVE PROVIDED TO US
          WITH THE UNDERSTANDING THAT YOU WILL BE RECEIVING TEXT MESSAGES FROM
          CLUTCH. YOU AGREE TO INDEMNIFY CLUTCH FOR TEXT MESSAGING CHARGES AND
          FEES THAT MAY RESULT FROM YOUR FAILURE TO PROVIDE SUCH NOTIFICATION TO
          CLUTCH.
        </p>
        <h3>Jurisdiction</h3>
        <p>
          By accessing the Services, you and Clutch agree that all matters
          relating to your access to, or use of, the Services shall be governed
          by the statutes and laws of the State of Georgia, without regard to
          its conflicts of laws principles. You and Clutch also agree, and
          submit to the exclusive personal jurisdiction and venue of the courts
          of the State of Georgia with respect to such matters. Clutch makes no
          representation that materials contained in the Services are
          appropriate or available for use in other locations, and accessing
          them from territories where their contents are illegal is prohibited.
          Those who choose to access the Services from other locations do so on
          their own initiative, and are responsible for compliance with local
          laws.
        </p>
        <h3>Notices</h3>
        <p>
          Clutch may deliver notice to you under these Terms of Use by means of
          e-mail, a general notice posted on this Website, or by written
          communication delivered by first-class U.S. mail to the address that
          you have provided to Clutch. You may give notice to, or submit
          comment, questions or complaints to, Clutch at any time via e-mail or
          by letter delivered by first-class postage prepaid U.S. mail or
          overnight courier to the following address:
        </p>
        <p>
          <strong>Clutch Health LLC</strong>
          <br />
          438 Porsche Ave <br />
          Atlanta, GA 30354 <br />
          Attn: Terms of Use/Plan Administrator
          <br />
          You can also contact us via email to:{" "}
          <a href="mailto:info@clutch.health">info@clutch.health</a>
          <br />
          Telephone: (844) 429-4279
        </p>
        <h3>Survival</h3>
        <p>
          The provisions of these Terms of Use entitled “Limitation of
          Liability,” “Disclaimer of Warranties,” “Indemnification,”
          “Jurisdiction” and “General Provisions” will survive the termination
          of this Agreement.
        </p>
        <h3>General Provisions</h3>
        <p>
          Except as provided in a particular “Legal Notice” on this Website,
          these Terms of Use, along with the Clutch Privacy Policy, constitute
          the entire agreement and understanding between you and Clutch with
          respect to use of the Services, superseding all prior or
          contemporaneous communications with Clutch. These Terms of Use are
          severable, and in the event any provision is determined to be invalid
          or unenforceable, such invalidity or unenforceability shall not in any
          way affect the validity or enforceability of the remaining provisions.
          A printed version of these Terms of Use shall be admissible in
          judicial or administrative proceedings based upon or relating to use
          of the Services to the same extent and subject to the same conditions
          as other business documents and records originally generated and
          maintained in printed form. The section titles of this Agreement are
          displayed for convenience only and have no legal effect. Nothing in
          this Agreement shall be deemed to confer any third-party rights or
          benefits.
        </p>
      </div>
    </>
  );
}

// style
const LinkText = styled.span`
  ${Theme.simplonNorm};
  display: inline-block;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  line-height: 21px;
  color: ${Theme.purple};
  margin-bottom: 15px;
  cursor: pointer;
  text-transform: uppercase;

  @media (min-width: ${Theme.breakpoints.md}) {
    ${Theme.openSans};
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-transform: none;
  }
`;
const IconBack = styled.img`
  display: inline-block;
  height: 9px;
  margin-right: 2px;
  transform: rotate(270deg);

  @media (min-width: ${Theme.breakpoints.md}) {
    heigt: 10px;
  }
`;
