import React from "react";
import gql from "graphql-tag";
import t from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import Error from "./Error";

MutationError.propTypes = {
  error: t.object,
  inputError: t.bool,
  overrideGQLMessage: t.string
};

const HAS_ERROR = gql`
  query IsGraphErrorPresent {
    hasGraphError @client
    currentGraphError @client
  }
`;

export default function MutationError({
  error,
  inputError,
  overrideGQLMessage
}) {
  const { data } = useQuery(HAS_ERROR);
  const defaultErrorMessage =
    "We're having trouble connecting. Please check your internet connection and try again.";

  // real network error
  if (error && error.message.includes("Failed to fetch")) {
    return <Error inputError={inputError}>{defaultErrorMessage}</Error>;
  }

  // graph error
  if (error && data && data.hasGraphError) {
    return (
      <Error inputError={inputError}>
        {overrideGQLMessage || data.currentGraphError}
      </Error>
    );
  }

  // probably a real server/network error
  if (error) {
    return (
      <Error inputError={inputError}>
        {error.message
          .replace("GraphQL error:", "")
          .replace("TypeError:", "") || defaultErrorMessage}
      </Error>
    );
  }

  return null;
}
