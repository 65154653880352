import React from "react";
import styled from "styled-components";
import t from "prop-types";

Fieldset.propTypes = {
  columns: t.bool
};

export default function Fieldset(props) {
  return <StyledFieldset {...props} />;
}

// style
const StyledFieldset = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 23px 8.7%;

  & + button {
    margin-top: 26px;
  }

  ${props => props.columns && "grid-template-columns: 1fr 1fr;"}
`;
