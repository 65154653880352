import React, { useState } from "react";
import styled from "styled-components";
import t from "prop-types";
import Card from "../Card";
import PharmacyModal from "./PharmacyModal";
import PharmacyListItem from "./PharmacyListItem";
import Theme from "../../styles/Theme";

// prop types
PharmacyList.propTypes = {
  isGeneric: t.bool.isRequired,
  pharmacies: t.array.isRequired,
  prescription: t.shape({
    id: t.string.isRequired,
    doseForm: t.string,
    doseStrength: t.string,
    quantity: t.number.isRequired,
    drugName: t.string.isRequired,
    genericDrugName: t.string,
    new: t.bool,
    state: t.oneOf(["unprocessed", "transferred", "filled", "reversed"]),
    instructions: t.string.isRequired,
    refillsRemaining: t.number.isRequired,
    doctor: t.shape({
      id: t.string.isRequired,
      firstName: t.string.isRequired,
      lastName: t.string.isRequired,
      phone: t.string
    }).isRequired
  }).isRequired
};

export default function PharmacyList({ isGeneric, prescription, pharmacies }) {
  const [modalOpen, setModalOpen] = useState({
    open: false,
    pharmacy: {}
  });

  return (
    <StyledCard>
      <SmallText>Pharmacies</SmallText>
      {pharmacies.map(function(pharmacy, index) {
        return (
          <PharmacyListItem
            setModalOpen={setModalOpen}
            pharmacy={pharmacy}
            key={index}
          />
        );
      })}
      {modalOpen.open && (
        <PharmacyModal
          prescription={prescription}
          pharmacy={modalOpen.pharmacy}
          isGeneric={isGeneric}
          onClose={() => setModalOpen(false)}
        />
      )}
    </StyledCard>
  );
}

// style
const SmallText = styled.div`
  ${Theme.smallText};
  background: ${Theme.windowBackground};
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 10px;
`;
const StyledCard = styled(Card)`
  ${Theme.fullBleedChild}
  margin-top: 33px;
  padding: 0;
  box-shadow: none;
  & > div:first-child {
    padding: 25px 16px;
  }

  @media (min-width: ${Theme.breakpoints.sm}) {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    & > div:first-child {
      padding: 12px 16px;
    }
  }
`;
