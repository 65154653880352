import gql from "graphql-tag";

export const prescriptionFragment = gql`
  fragment PrescriptionFields on Prescription {
    id
    doctor {
      id
      firstName
      lastName
    }
    doseForm
    doseStrength
    quantity
    drugName
    genericDrugName
    new
    state
    instructions
    refillsRemaining
    pharmacy {
      id
      pharmacy
      phoneNumber
      price
      address {
        street1
        street2
        city
        state
        zipCode
      }
    }
  }
`;
