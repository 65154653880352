import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { format } from "date-fns";
import Error from "../components/Error";
import Loader from "../components/Loader";
import Updates from "../components/Dashboard/Updates";
import Resources from "../components/Dashboard/Resources";
import Theme, { FadeIn } from "../styles/Theme";
import styled from "styled-components";

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      email
      firstName
      lastName
      flags {
        mustVerifyPhone
        mustUpdatePassword
      }
      dashboardItems {
        id
        category
        createdAt
        imageUrl
        title
        url
      }
      prescriptions {
        id
        doctor {
          id
          firstName
          lastName
        }
        doseForm
        doseStrength
        drugName
        new
        quantity
      }
    }
  }
`;

export default function DashboardPage() {
  const { loading, data, error } = useQuery(CURRENT_USER);

  if (loading) return <Loader />;
  if (error) return <Error>{error.message}</Error>;

  return (
    <FadeIn>
      <Today>{format(new Date(), "EEEE, LLLL d")}</Today>
      <h1>Dashboard</h1>
      <FlexWrap>
        <Updates prescriptions={data.currentUser.prescriptions} />
        <Resources items={data.currentUser.dashboardItems} />
      </FlexWrap>
    </FadeIn>
  );
}

// styles
const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  max-width: 345px;
  margin: 0 auto;

  @media (min-width: ${Theme.breakpoints.md}) {
    flex-direction: row;
    max-width: none;
  }
`;
const Today = styled.div`
  ${Theme.smallText}
  color: ${Theme.textMedium};
  margin-bottom: 10px;
`;
