import React from "react";
import gql from "graphql-tag";
import t from "prop-types";
import { Formik } from "formik";
import { useLazyQuery } from "@apollo/react-hooks";
import * as yup from "yup";
import Button from "../Button";
import Datepicker from "../FormFields/Datepicker";
import Fieldset from "../FormFields/Fieldset";
import Select from "../FormFields/Select";
import Input from "../FormFields/Input";
import Error from "../Error";
import Loader from "../Loader";
import {
  LoaderWrap,
  LoggedOutPageCard,
  Heading,
  Intro
} from "../../pages/CreateAccountPage";

FindAccount.propTypes = {
  state: t.shape({
    uiStep: t.oneOf(["findAccount", "createUser", "verifyPhone"]).isRequired,
    userDetails: t.shape({
      dateOfBirth: t.oneOfType([t.string, t.instanceOf(Date)]),
      email: t.string,
      firstName: t.string,
      lastName: t.string,
      password: t.string,
      phone: t.string,
      registrationCode: t.string,
      sex: t.oneOf(["", "male", "female"])
    }).isRequired
  }),
  setState: t.func.isRequired
};

const FIND_USER = gql`
  query FindUser(
    $lastName: String!
    $dateOfBirth: Date!
    $sex: Sex!
    $registrationCode: String!
  ) {
    findUser(
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      sex: $sex
      registrationCode: $registrationCode
    ) {
      id
      firstName
      lastName
    }
  }
`;
export default function FindAccount({ state, setState }) {
  const [findUser, { loading, error }] = useLazyQuery(FIND_USER, {
    onCompleted() {
      setState({
        ...state,
        uiStep: "createUser"
      });
    }
  });

  return (
    <LoggedOutPageCard>
      <Heading>Welcome to Clutch!</Heading>
      <Intro>
        We make it easier than ever to find the closest pharmacies to fill your
        prescriptions at the lowest prices. Let’s get started!
      </Intro>
      <Formik
        initialValues={{
          registrationCode: "",
          lastName: "",
          dateOfBirth: null,
          sex: ""
        }}
        onSubmit={async values => {
          setState({
            ...state,
            userDetails: {
              ...state.userDetails,
              ...values
            }
          });
          findUser({ variables: values });
        }}
        validationSchema={yup.object().shape({
          registrationCode: yup.string().required("Required"),
          lastName: yup.string().required("Required"),
          dateOfBirth: yup
            .string()
            .nullable()
            .required("Required"),
          sex: yup.string().required("Required")
        })}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Fieldset>
                <div>
                  <Input
                    name="registrationCode"
                    label="Employer Code"
                    fullWidth
                    value={values.registrationCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched.registrationCode && !!errors.registrationCode
                    }
                  />
                  {errors.registrationCode && touched.registrationCode && (
                    <Error inputError>{errors.registrationCode}</Error>
                  )}
                </div>
                <div>
                  <Input
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.lastName && !!errors.lastName}
                  />
                  {errors.lastName && touched.lastName && (
                    <Error inputError>{errors.lastName}</Error>
                  )}
                </div>
                <div>
                  <Datepicker
                    name="dateOfBirth"
                    label="Birthday"
                    value={values.dateOfBirth}
                    onBlur={handleBlur}
                    hasError={touched.dateOfBirth && !!errors.dateOfBirth}
                  />
                  {errors.dateOfBirth && touched.dateOfBirth && (
                    <Error inputError>{errors.dateOfBirth}</Error>
                  )}
                </div>
                <div>
                  <Select
                    name="sex"
                    label="Sex"
                    options={[
                      { value: "male", label: "Male" },
                      { value: "female", label: "Female" }
                    ]}
                    value={values.sex}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.sex && !!errors.sex}
                  />
                  {errors.sex && touched.sex && (
                    <Error inputError>{errors.sex}</Error>
                  )}
                </div>
              </Fieldset>

              <Button
                type="submit"
                size="full"
                disabled={
                  isSubmitting ||
                  loading ||
                  Object.values(values).some(value => !value)
                }
              >
                Find Account
              </Button>
            </form>
          );
        }}
      </Formik>
      {loading && (
        <LoaderWrap>
          <Loader />
        </LoaderWrap>
      )}
      {error && error.graphQLErrors && (
        <Error>{error.graphQLErrors[0].message}</Error>
      )}
    </LoggedOutPageCard>
  );
}
