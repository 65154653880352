import { createGlobalStyle } from "styled-components";

import ModeratRegular from "./moderat-regular-webfont.woff";
import ModeratBold from "./moderat-bold-webfont.woff";
import SimplonNormRegular from "./simplonnorm-regular-webfont.woff";
import SimplonNormMedium from "./simplonnorm-medium-webfont.woff";
import SimplonNormBold from "./simplonnorm-bold-webfont.woff";
import OpenSansRegular from "./OpenSans-Regular.ttf";
import OpenSansBold from "./OpenSans-Bold.ttf";

export default createGlobalStyle`
  @font-face {
    font-family: 'Moderat';
    src: url(${ModeratRegular}) format('woff');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Moderat';
    src: url(${ModeratBold}) format('woff');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Simplon Norm';
    src: url(${SimplonNormRegular}) format('woff');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Simplon Norm';
    src: url(${SimplonNormMedium}) format('woff');
    font-weight: 500;
  }
  @font-face {
    font-family: 'Simplon Norm';
    src: url(${SimplonNormBold}) format('woff');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansRegular}) format('ttf');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansBold}) format('ttf');
    font-weight: 700;
  }
`;
