import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Button from "./Button";
import Loader from "./Loader";
import MutationError from "./MutationError";
import PhoneField from "./FormFields/PhoneField";

const CURRENT_PHONE = gql`
  query CurrentPhone {
    currentUser {
      id
      phone
    }
  }
`;
const UPDATE_PHONE = gql`
  mutation UpdatePhone($phone: String!) {
    updateUser(phone: $phone) {
      id
      phone
    }
  }
`;

export default function ChangePhoneNumber() {
  const [value, setValue] = useState("");

  const { data } = useQuery(CURRENT_PHONE);
  useEffect(() => {
    if (data) setValue(data.currentUser.phone);
  }, [data]);

  const [editable, setEditable] = useState(false);
  const toggleEditable = () => setEditable(!editable);

  const handleChange = e => {
    if (editable) setValue(e.target.value);
  };

  const [updatePhone, { loading, error, client }] = useMutation(UPDATE_PHONE, {
    onError() {
      // this callback prevents apollo from throwing
      // ...unhandled exception on 400 status code
    },
    onCompleted() {
      // this clears the global cached error "hack"
      client.writeData({ data: { hasGraphError: false } });
    }
  });

  return (
    <>
      <Wrapper>
        <InputWrap>
          <PhoneField
            name="phone"
            label="Mobile Phone"
            onChange={handleChange}
            disabled={!editable}
            value={value}
          />
        </InputWrap>
        <ToggleWrap>
          {loading && (
            <Loader size={24} thickness={4} style={{ margin: "0 0 5px 5px" }} />
          )}
          {!loading && (
            <StyledButton
              variation="ghost"
              onClick={() => {
                if (editable) updatePhone({ variables: { phone: value } });
                toggleEditable();
              }}
            >
              {!loading && editable ? "Save" : "Change"}
            </StyledButton>
          )}
        </ToggleWrap>
      </Wrapper>
      {error && <MutationError inputError error={error} />}
    </>
  );
}

// style
const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
const InputWrap = styled.div`
  flex-grow: 1;
`;
const ToggleWrap = styled.div`
  margin: 0 0 5px 5px;
  min-width: 52px;
  text-align: center;
`;
const StyledButton = styled(Button)`
  padding: 4px 8px;
  font-size: 10px;
  line-height: 16px;
`;
