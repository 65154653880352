import React, { useState } from "react";
import get from "lodash.get";
import gql from "graphql-tag";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import omit from "lodash.omit";
import styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Formik, getIn } from "formik";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as yup from "yup";
import Button from "../components/Button";
import Checkbox from "../components/FormFields/Checkbox";
import ClutchCard from "../components/ClutchCard";
import Datepicker from "../components/FormFields/Datepicker";
import Error from "../components/Error";
import Fieldset from "../components/FormFields/Fieldset";
import Input from "../components/FormFields/Input";
import Loader from "../components/Loader";
import MutationError from "../components/MutationError";
import PasswordConstraints, {
  passwordSchema
} from "../components/FormFields/PasswordConstraints";
import PasswordField from "../components/FormFields/PasswordField";
import PhoneField, { phoneRegExp } from "../components/FormFields/PhoneField";
import Select from "../components/FormFields/Select";
import Theme, { FadeIn } from "../styles/Theme";
import { TOGGLE_FLASH } from "../components/FlashNotifier";
import { ReactComponent as Check } from "../static/icons/check.svg";

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      firstName
      lastName
      dateOfBirth
      sex
      email
      phone
      address {
        id
        street1
        street2
        city
        state
        zipCode
      }
      walletCard {
        memberId
        rxBin
        rxGroup
        rxPcn
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $email: String
    $phone: String
    $firstName: String
    $lastName: String
    $name: String
    $dateOfBirth: Date
    $sex: Sex
    $address: AddressInput
    $withPassword: Boolean!
    $password: String!
  ) {
    updateUser(
      email: $email
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      name: $name
      dateOfBirth: $dateOfBirth
      sex: $sex
      address: $address
    ) {
      id
      firstName
      lastName
      dateOfBirth
      sex
      email
      phone
      address {
        id
        street1
        street2
        city
        state
        zipCode
      }
      flags {
        mustVerifyPhone
      }
    }
    updatePassword(password: $password) @include(if: $withPassword) {
      id
    }
  }
`;

export default function AccountPage() {
  // fetching and mutating
  const { data, loading, error } = useQuery(CURRENT_USER);
  const [
    updateUser,
    { loading: updateLoading, error: updateError, client }
  ] = useMutation(UPDATE_USER, {
    onError() {
      // this callback prevents apollo from throwing
      // ...unhandled exception on 400 status code
    },
    onCompleted() {
      toggleFlash({
        variables: { message: "Account details updated!", icon: <Check /> }
      });
      // this callback clears the global cached error "hack"
      client.writeData({
        data: {
          hasGraphError: false
        }
      });
    }
  });

  // side effect hooks
  const [toggleFlash] = useMutation(TOGGLE_FLASH);
  const useColumns = useMediaQuery(`(min-width: ${Theme.breakpoints.lg})`);

  // dynamic password status
  const [editingPassword, setEditingPassword] = useState(false);
  const handlePasswordCheckbox = (e, setFieldValue, setErrors, errors) => {
    setEditingPassword(e.target.checked);
    if (e.target.checked) {
      setErrors({
        ...errors,
        password: true
      });
    } else {
      setFieldValue("password", "").then(() =>
        setErrors(omit(errors, "password"))
      );
    }
  };

  // abort if
  if (loading) return <Loader />;
  if (error) return <Error>{error.message}</Error>;

  // set up initial values, validation rules
  const { currentUser } = data;
  const initialValues = {
    ...omit(currentUser, ["id", "__typename", "walletCard"]),
    address: {
      ...omit(currentUser.address, ["id", "__typename"]),
      street2: get(currentUser, "address.street2", "")
    },
    password: ""
  };
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    dateOfBirth: yup
      .string()
      .nullable()
      .required("Required"),
    email: yup
      .string()
      .email()
      .required("Required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    address: yup.object().shape({
      zipCode: yup.string().matches(/^[1-9-]/, "Must be a valid zip code")
    }),
    password: editingPassword ? passwordSchema.required("Required") : null
  });

  return (
    <FadeIn>
      <h1>Account</h1>
      <Layout>
        <Left>
          <Formik
            initialValues={initialValues}
            onSubmit={async values =>
              updateUser({
                variables: {
                  ...values,
                  withPassword: editingPassword
                }
              }).then((values.password = ""))
            }
            validationSchema={validationSchema}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              isSubmitting,
              setErrors,
              setFieldValue
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormSection>
                    <h4>Account Profile</h4>
                    <Fieldset columns={useColumns}>
                      <div>
                        <Input
                          label="First Name"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={touched.firstName && !!errors.firstName}
                        />
                        {errors.firstName && touched.firstName && (
                          <Error inputError>{errors.firstName}</Error>
                        )}
                      </div>
                      <div>
                        <Input
                          label="Last Name"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={touched.lastName && !!errors.lastName}
                        />
                        {errors.lastName && touched.lastName && (
                          <Error inputError>{errors.lastName}</Error>
                        )}
                      </div>
                      <div>
                        <Datepicker
                          name="dateOfBirth"
                          label="Birthday"
                          value={values.dateOfBirth}
                          onBlur={handleBlur}
                          hasError={touched.dateOfBirth && !!errors.dateOfBirth}
                        />
                        {errors.dateOfBirth && touched.dateOfBirth && (
                          <Error inputError>{errors.dateOfBirth}</Error>
                        )}
                      </div>
                      <div>
                        <Select
                          name="sex"
                          label="Sex"
                          options={[
                            { value: "male", label: "Male" },
                            { value: "female", label: "Female" }
                          ]}
                          value={values.sex}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </Fieldset>
                  </FormSection>

                  <FormSection>
                    <h4>Contact Info</h4>
                    <Fieldset columns={useColumns}>
                      <div>
                        <Input
                          name="email"
                          label="Email Address"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={touched.email && !!errors.email}
                        />
                        {errors.email && touched.email && (
                          <Error inputError>{errors.email}</Error>
                        )}
                      </div>
                      <div>
                        <PhoneField
                          label="Phone Number"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={touched.phone && !!errors.phone}
                        />
                        {errors.phone && touched.phone && (
                          <Error inputError>{errors.phone}</Error>
                        )}
                      </div>
                      <div>
                        <Input
                          label="Street Address"
                          name="address.street1"
                          value={values.address.street1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={
                            getIn(touched, "address.street1") &&
                            !!getIn(errors, "address.street1")
                          }
                        />
                        {getIn(errors, "address.street1") &&
                          getIn(touched, "address.street1") && (
                            <Error inputError>{errors.address.street1}</Error>
                          )}
                      </div>
                      <div>
                        <Input
                          label="Address line 2"
                          name="address.street2"
                          value={values.address.street2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={
                            getIn(touched, "address.street2") &&
                            !!getIn(errors, "address.street2")
                          }
                        />
                        {getIn(errors, "address.street2") &&
                          getIn(touched, "address.street2") && (
                            <Error inputError>{errors.address.street2}</Error>
                          )}
                      </div>
                      <div>
                        <Input
                          label="City"
                          name="address.city"
                          value={values.address.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={
                            getIn(touched, "address.city") &&
                            !!getIn(errors, "address.city")
                          }
                        />
                        {getIn(errors, "address.city") &&
                          getIn(touched, "address.city") && (
                            <Error inputError>{errors.address.city}</Error>
                          )}
                      </div>
                      <div>
                        <Select
                          label="State"
                          name="address.state"
                          options={states.map(state => ({
                            value: state.abbreviation,
                            label: state.name
                          }))}
                          value={values.address.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div>
                        <Input
                          label="Zip Code"
                          name="address.zipCode"
                          value={values.address.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={
                            getIn(touched, "address.zipCode") &&
                            !!getIn(errors, "address.zipCode")
                          }
                        />
                        {getIn(errors, "address.zipCode") &&
                          getIn(touched, "address.zipCode") && (
                            <Error inputError>{errors.address.zipCode}</Error>
                          )}
                      </div>
                    </Fieldset>
                  </FormSection>

                  <FormSection>
                    <h4>Password & Security</h4>
                    <Checkbox
                      onChange={e =>
                        handlePasswordCheckbox(
                          e,
                          setFieldValue,
                          setErrors,
                          errors
                        )
                      }
                      label="Change my password"
                    />

                    {editingPassword && (
                      <PasswordSection>
                        <PasswordField
                          name="password"
                          label="New Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={touched.password && !!errors.password}
                        />
                        {errors.password && touched.password && (
                          <Error inputError>{errors.password}</Error>
                        )}
                        <PasswordConstraints value={values.password} />
                      </PasswordSection>
                    )}
                  </FormSection>
                  <ButtonLoaderWrap>
                    <Button
                      type="submit"
                      disabled={
                        isEqual(initialValues, values) ||
                        !isEmpty(errors) ||
                        isSubmitting
                      }
                    >
                      Save Changes
                    </Button>
                    {updateLoading && <Loader size={20} thickness={4} />}
                  </ButtonLoaderWrap>
                  {updateError && <MutationError error={updateError} />}
                </form>
              );
            }}
          </Formik>
        </Left>
        <Right>
          <h4>Pharmacy Savings Card</h4>
          <ClutchCard {...currentUser.walletCard} />
        </Right>
      </Layout>
    </FadeIn>
  );
}

// style
const Layout = styled.div`
  max-width: 345px;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 740px) {
    flex-direction: row;
    max-width: none;
  }
`;
const Left = styled.section`
  margin: 40px 0;
  @media (min-width: 740px) {
    width: 50%;
    margin: 0;
    border-right: 1px solid ${Theme.windowBackground};
    padding-right: 5%;
  }
  @media (min-width: ${Theme.breakpoints.lg}) {
    padding-right: 8%;
  }
`;
const Right = styled.section`
  margin: 20px 0;
  @media (min-width: 740px) {
    width: 50%;
    margin: 0;
    padding-left: 5%;
  }
  @media (min-width: ${Theme.breakpoints.lg}) {
    padding-left: 8%;
  }
`;
const FormSection = styled.div`
  margin-bottom: 54px;
`;
const PasswordSection = styled.div`
  max-width: 300px;
  margin-top: 26px;
`;
const ButtonLoaderWrap = styled.footer`
  display: flex;
  align-items: center;

  button {
    margin-right: 20px;
  }
`;

// states
const states = [
  {
    name: "Alaska",
    abbreviation: "AK"
  },
  {
    name: "Alabama",
    abbreviation: "AL"
  },
  {
    name: "Arkansas",
    abbreviation: "AR"
  },
  {
    name: "Arizona",
    abbreviation: "AZ"
  },
  {
    name: "California",
    abbreviation: "CA"
  },
  {
    name: "Colorado",
    abbreviation: "CO"
  },
  {
    name: "Connecticut",
    abbreviation: "CT"
  },
  {
    name: "District of Columbia",
    abbreviation: "DC"
  },
  {
    name: "Delaware",
    abbreviation: "DE"
  },
  {
    name: "Florida",
    abbreviation: "FL"
  },
  {
    name: "Georgia",
    abbreviation: "GA"
  },
  {
    name: "Hawaii",
    abbreviation: "HI"
  },
  {
    name: "Iowa",
    abbreviation: "IA"
  },
  {
    name: "Idaho",
    abbreviation: "ID"
  },
  {
    name: "Illinois",
    abbreviation: "IL"
  },
  {
    name: "Indiana",
    abbreviation: "IN"
  },
  {
    name: "Kansas",
    abbreviation: "KS"
  },
  {
    name: "Kentucky",
    abbreviation: "KY"
  },
  {
    name: "Louisiana",
    abbreviation: "LA"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA"
  },
  {
    name: "Maryland",
    abbreviation: "MD"
  },
  {
    name: "Maine",
    abbreviation: "ME"
  },
  {
    name: "Michigan",
    abbreviation: "MI"
  },
  {
    name: "Minnesota",
    abbreviation: "MN"
  },
  {
    name: "Missouri",
    abbreviation: "MO"
  },
  {
    name: "Mississippi",
    abbreviation: "MS"
  },
  {
    name: "Montana",
    abbreviation: "MT"
  },
  {
    name: "North Carolina",
    abbreviation: "NC"
  },
  {
    name: "North Dakota",
    abbreviation: "ND"
  },
  {
    name: "Nebraska",
    abbreviation: "NE"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ"
  },
  {
    name: "New Mexico",
    abbreviation: "NM"
  },
  {
    name: "Nevada",
    abbreviation: "NV"
  },
  {
    name: "New York",
    abbreviation: "NY"
  },
  {
    name: "Ohio",
    abbreviation: "OH"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK"
  },
  {
    name: "Oregon",
    abbreviation: "OR"
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA"
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI"
  },
  {
    name: "South Carolina",
    abbreviation: "SC"
  },
  {
    name: "South Dakota",
    abbreviation: "SD"
  },
  {
    name: "Tennessee",
    abbreviation: "TN"
  },
  {
    name: "Texas",
    abbreviation: "TX"
  },
  {
    name: "Utah",
    abbreviation: "UT"
  },
  {
    name: "Virginia",
    abbreviation: "VA"
  },
  {
    name: "Vermont",
    abbreviation: "VT"
  },
  {
    name: "Washington",
    abbreviation: "WA"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI"
  },
  {
    name: "West Virginia",
    abbreviation: "WV"
  },
  {
    name: "Wyoming",
    abbreviation: "WY"
  }
];
