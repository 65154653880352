import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Theme from "../../styles/Theme";
import { StyledCard } from "./VideoCard";

// prop types
ArticleCard.propTypes = {
  bgImage: PropTypes.string.isRequired,
  link: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default function ArticleCard({ title, subtitle, link, bgImage }) {
  return (
    <StyledCard>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <MediaWrapper>
          <Media image={bgImage} />
        </MediaWrapper>
        <TextWrapper>
          <Subtitle>{subtitle}</Subtitle>
          <Title>{title}</Title>
        </TextWrapper>
      </a>
    </StyledCard>
  );
}

// style
const MediaWrapper = styled.div`
  position: relative;
  height: 240px;
  width: 100%;
  cursor: pointer;
`;
const Media = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`;
const TextWrapper = styled.div`
  padding: 16px 14px;
  background-color: ${Theme.lightBlue};
`;
const Subtitle = styled.span`
  color: ${Theme.purple};
  font-size: 1.6rem;
  line-height: 2.1rem;
`;
const Title = styled.h4`
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin: 4px 0 0;
  cursor: pointer;
`;
