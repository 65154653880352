import React from "react";
import styled from "styled-components";
import t from "prop-types";
import Card from "./Card";
import Theme from "../styles/Theme";

// prop types
EmptyTable.propTypes = {
  title: t.string.isRequired,
  message: t.node.isRequired,
  loadingContent: t.node,
  className: t.string
};

export default function EmptyTable({
  title,
  message,
  loadingContent,
  className
}) {
  return (
    <StyledCard className={className}>
      <SmallText>{title}</SmallText>
      {loadingContent ? loadingContent : <Message>{message}</Message>}
    </StyledCard>
  );
}

// style
const StyledCard = styled(Card)`
  padding: 0;
`;
const SmallText = styled.div`
  ${Theme.smallText};
  background: ${Theme.windowBackground};
  width: 100%;
  padding: 12px 16px;
`;
const Message = styled.div`
  font-family: ${Theme.moderat};
  color: ${Theme.textLight};
  padding: 21px 16px;
`;
