import React, { useState } from "react";
import styled from "styled-components";
import t from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";
import iconSort from "../../static/icons/icon-sort.svg";
import PrescriptionStatus from "../PrescriptionStatus";
import Theme from "../../styles/Theme";

// prop types
PrescriptionList.propTypes = {
  prescriptions: t.arrayOf(
    t.shape({
      id: t.string.isRequired,
      doseForm: t.string,
      doseStrength: t.string,
      quantity: t.number.isRequired,
      drugName: t.string.isRequired,
      genericDrugName: t.string,
      new: t.bool,
      state: t.oneOf(["unprocessed", "transferred", "filled", "reversed"]),
      instructions: t.string.isRequired,
      refillsRemaining: t.number.isRequired,
      doctor: t.shape({
        id: t.string.isRequired,
        firstName: t.string.isRequired,
        lastName: t.string.isRequired,
        phone: t.string
      }).isRequired
    })
  ).isRequired
};

export default function PrescriptionList({ prescriptions }) {
  // routing
  let { url } = useRouteMatch();

  // sorting
  let sortedPrescriptions = [...prescriptions];
  const statusCodes = {
    unprocessed: 0,
    transferred: 1,
    filled: 2,
    reversed: 3
  };
  const [sortedState, setSortedState] = useState({
    column: "state",
    direction: "descending"
  });
  sortedPrescriptions.sort((a, b) => {
    if (sortedState.column === "state") {
      if (statusCodes[a.state] < statusCodes[b.state]) {
        return sortedState.direction === "ascending" ? 1 : -1;
      }
      if (statusCodes[a.state] > statusCodes[b.state]) {
        return sortedState.direction === "ascending" ? -1 : 1;
      }
      return 0;
    } else {
      if (a[sortedState.column] < b[sortedState.column]) {
        return sortedState.direction === "ascending" ? 1 : -1;
      }
      if (a[sortedState.column] > b[sortedState.column]) {
        return sortedState.direction === "ascending" ? -1 : 1;
      }
      return 0;
    }
  });
  const handleSort = column => {
    let direction = "ascending";
    if (
      sortedState.column === column &&
      sortedState.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortedState({ column, direction });
  };

  // render
  return (
    <Table>
      <thead>
        <tr>
          <SortableTableHead onClick={() => handleSort("drugName")}>
            Prescription{" "}
            {sortedState.column === "drugName" && (
              <SortIcon src={iconSort} direction={sortedState.direction} />
            )}
          </SortableTableHead>
          <SortableTableHead onClick={() => handleSort("doctor")}>
            Physician{" "}
            {sortedState.column === "doctor" && (
              <SortIcon src={iconSort} direction={sortedState.direction} />
            )}
          </SortableTableHead>
          <th>Amount</th>
          <SortableTableHead onClick={() => handleSort("state")}>
            Status{" "}
            {sortedState.column === "state" && (
              <SortIcon src={iconSort} direction={sortedState.direction} />
            )}
          </SortableTableHead>
        </tr>
      </thead>
      <tbody>
        {sortedPrescriptions.map(prescription => (
          <tr key={prescription.id}>
            <td>
              <Link
                to={`${url}/${prescription.id}-${encodeURI(
                  prescription.drugName
                )}`}
              >
                <PrescriptionLink>{prescription.drugName}</PrescriptionLink>
              </Link>
            </td>
            <td>
              Dr. {prescription.doctor.firstName} {prescription.doctor.lastName}
            </td>
            <td>
              {prescription.doseStrength} / {prescription.quantity}{" "}
              {prescription.doseForm}
            </td>
            <td>
              <PrescriptionStatus status={prescription.state} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

// style
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead {
    background: ${Theme.windowBackground};
  }
  th {
    ${Theme.smallText}
    text-align: left;
    padding: 12px 16px;
  }
  tr {
    border-bottom: 1px solid ${Theme.windowBackground};
  }
  td {
    padding: 24px 16px;
  }
`;
const SortableTableHead = styled.th`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
const SortIcon = styled.img`
  display: inline-block;
  margin-left: 2px;
  ${props => props.direction === "descending" && "transform: rotate(180deg);"}
`;
const PrescriptionLink = styled.h3`
  cursor: pointer;
`;
