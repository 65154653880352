import React from "react";
import t from "prop-types";
import styled, { css } from "styled-components";
import Theme from "../styles/Theme";

// prop types
Button.propTypes = {
  disabled: t.bool,
  onClick: t.func,
  size: t.oneOf(["small", "medium", "large", "full"]),
  variation: t.oneOf(["normal", "ghost"])
};

export default function Button({
  onClick,
  disabled,
  size = "medium",
  variation = "normal",
  ...props
}) {
  return (
    <StyledButton
      className={props.className}
      disabled={disabled}
      onClick={onClick}
      size={size}
      variation={variation}
      {...props}
    >
      {props.children}
    </StyledButton>
  );
}

// style
const StyledButton = styled.button`
  font-size: 14px;
  line-height: 19px;
  padding: 11px 30px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border: none;
  background: ${Theme.orange};
  color: ${Theme.uiWhite};
  transition: ${Theme.transition};

  &:hover {
    background: ${Theme.orangeHover}
  }
  &:focus {
    box-shadow: 0 0 0 4px rgba(0,0,0,.1);
    outline: none;
  }

  /* Color variations */
  ${props => props.variation === "ghost" && ghostStyles}

  /* Sizes */
  ${props => props.size === "full" && "width: 100%;"}
  ${props => props.size === "large" && "padding: 11px 88px;"}
  ${props => props.size === "small" && smallStyles}

  /* Disabled */
  ${props => props.disabled && disabledStyles}
`;

const smallStyles = css`
  padding: 5px 18px 4px;
  ${Theme.smallText}
`;

const ghostStyles = css`
  color: ${Theme.orange};
  background: transparent;
  transition: ${Theme.transition};

  &:hover,
  &:focus {
    box-shadow: none;
    background: ${Theme.orangeGhost};
  }
`;

const disabledStyles = css`
  background: ${Theme.uiLighter} !important;
  cursor: not-allowed;
`;
