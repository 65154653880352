import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useMediaQuery } from "@material-ui/core";
import Theme from "../styles/Theme";
import Navigation from "./Navigation";

// prop types
AppChrome.propTypes = {
  children: PropTypes.node
};

export default function AppChrome({ children }) {
  const isDesktop = useMediaQuery(`(min-width: ${Theme.breakpoints.md})`);

  return (
    <Wrapper style={{ flexDirection: isDesktop ? "row" : "column" }}>
      <Navigation isDesktop={isDesktop} />
      <Main>{children}</Main>
    </Wrapper>
  );
}

// styles
const Wrapper = styled.div`
  display: flex;
`;
const Main = styled.div`
  padding: 22px 16px;

  @media (min-width: ${Theme.breakpoints.sm}) {
    padding: 28px 34px;
    flex-grow: 1;
  }
`;
