import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Snackbar from "./Snackbar";

export const GET_FLASH_MESSAGES = gql`
  query GetFlashMessages {
    flashMessages @client {
      id
      message
      icon
      color
    }
  }
`;

export const TOGGLE_FLASH = gql`
  mutation addOrRemoveFlash(
    $id: ID
    $message: StringOrReactComponent
    $icon: ReactComponent
    $color: String
  ) {
    addOrRemoveFlash(id: $id, message: $message, icon: $icon, color: $color)
      @client
  }
`;

export default function FlashNotifier() {
  const { data } = useQuery(GET_FLASH_MESSAGES);
  const [toggleFlash] = useMutation(TOGGLE_FLASH);

  return data.flashMessages.map(flashMessage => {
    return (
      <Snackbar
        key={flashMessage.id}
        message={flashMessage.message}
        icon={flashMessage.icon}
        color={flashMessage.color || ""}
        onClose={() => toggleFlash({ variables: { id: flashMessage.id } })}
      />
    );
  });
}
