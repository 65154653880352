import React from "react";
import t from "prop-types";
import isEmpty from "lodash.isempty";
import { Formik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Theme from "../../styles/Theme";
import Button from "../Button";
import Error from "../Error";
import Fieldset from "../FormFields/Fieldset";
import Input from "../FormFields/Input";
import PasswordField from "../FormFields/PasswordField";
import PasswordConstraints, {
  passwordSchema
} from "../FormFields/PasswordConstraints";
import {
  LoggedOutPageCard,
  Heading,
  Intro
} from "../../pages/CreateAccountPage";

/**
 * NOTE: This component is called Create Account,
 * because that is what it appears like to the user
 * However, we actually do not create the account until the next step
 * since phone number is a required paramater for account creation
 */

CreateUser.propTypes = {
  state: t.shape({
    uiStep: t.oneOf(["findAccount", "createUser", "verifyPhone"]).isRequired,
    userDetails: t.shape({
      dateOfBirth: t.instanceOf(Date),
      email: t.string,
      firstName: t.string,
      lastName: t.string,
      password: t.string,
      phone: t.string,
      registrationCode: t.string,
      sex: t.oneOf(["", "male", "female"])
    }).isRequired
  }),
  setState: t.func.isRequired
};

export default function CreateUser({ state, setState }) {
  const { registrationCode, lastName, dateOfBirth, sex } = state.userDetails;

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required("Required"),
    firstName: yup.string().required("Required"),
    password: passwordSchema.required("Required")
  });

  return (
    <LoggedOutPageCard>
      <Heading>Employer Found!</Heading>
      <Intro>
        Now that we’ve identified your eligibility, let’s get your account
        created.
      </Intro>
      <Formik
        initialValues={{
          // from last form
          registrationCode,
          lastName,
          dateOfBirth,
          sex,
          // this form
          firstName: "",
          email: "",
          password: ""
        }}
        onSubmit={async values => {
          setState({
            ...state,
            uiStep: "verifyPhone",
            userDetails: {
              ...state.userDetails,
              ...values
            }
          });
        }}
        validationSchema={validationSchema}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Fieldset>
                <div>
                  <Input
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.firstName && !!errors.firstName}
                  />
                  {errors.firstName && touched.firstName && (
                    <Error inputError>{errors.firstName}</Error>
                  )}
                </div>
                <div>
                  <Input
                    name="email"
                    label="Email Address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.email && !!errors.email}
                  />
                  {errors.email && touched.email && (
                    <Error inputError>{errors.email}</Error>
                  )}
                </div>
                <div>
                  <PasswordField
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.password && !!errors.password}
                  />
                  {errors.password && touched.password && (
                    <Error inputError>{errors.password}</Error>
                  )}
                  <PasswordConstraints value={values.password} />
                </div>
              </Fieldset>
              <Button
                size="full"
                type="submit"
                disabled={
                  isSubmitting ||
                  !isEmpty(errors) ||
                  Object.values(values).some(value => !value)
                }
              >
                Create
              </Button>
              <Terms>
                By clicking “Create Account” above you are agreeing to Clutch’s{" "}
                <a
                  href="https://clutch.health/terms-of-usage/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="https://clutch.health/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </Terms>
            </form>
          );
        }}
      </Formik>
    </LoggedOutPageCard>
  );
}

// style
export const Terms = styled.div`
  font-size: 10px;
  line-height: 16px;
  margin-top: 12px;
  color: ${Theme.textMedium};
`;
