import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Theme from "../styles/Theme";
import iconSort from "../static/icons/icon-sort.svg";

export default function PrivacyPolicyPage() {
  return (
    <>
      <Link to="/">
        <LinkText>
          <IconBack src={iconSort} /> Dashboard
        </LinkText>
      </Link>
      <h1>Privacy Policy</h1>

      <div>
        <p>
          Clutch Health LLC (“Clutch”) is committed to maintaining the privacy
          and security of your personal information, and we appreciate that
          medical information is particularly sensitive. This Privacy Policy
          applies to any products or services offered through any Clutch mobile
          application (a “Mobile App”) that links to this Privacy Policy
          (collectively, the “Clutch Services”). Clutch respects the privacy of
          its healthcare providers (“Providers”), subscribers to the Clutch
          Services (“Members”) and Website visitors (“Visitors”). This Privacy
          Policy discloses Clutch’s information collection and dissemination
          practices in connection with the Clutch Services and applies solely to
          the information that we collect through the Clutch Services and from
          Visitors to the Website. This Privacy Policy does not address personal
          information that you provide to us in other contexts (e.g., through a
          business relationship not handled through a Website or Mobile App).
        </p>
        <h3>Acceptance of Privacy Policy</h3>
        <p>
          By clicking your acceptance of this Privacy Policy or using the Mobile
          App, you agree to the terms of this Privacy Policy. If you do not
          agree to the terms of this Privacy Policy, please do not use the
          Clutch Services. Your continued use of the Clutch Services following
          the posting of changes to these terms will mean that you accept those
          changes.
        </p>
        <h3>Personal Information Provided by You</h3>
        <p>
          Clutch collects your personally identifiable information (“PII”)
          through the Clutch Services when you choose to provide such
          information, such as when registering to become a Clutch member so
          that you may receive information and updates regarding the Clutch
          Services. PII can include your name, prescription and over-the-counter
          medications, address, telephone number, e-mail address, Social
          Security number, date of birth, healthcare coverage, pharmacy, and
          physician.
        </p>
        <h3>
          Clutch’s Collection of Your Publicly Available Personal Information
        </h3>
        <p>
          Clutch may request publicly available personal information about you
          that is maintained by the consumer data companies Acxiom and/or
          LexisNexis or other similar services. This publicly available personal
          information is used by Clutch to verify your identity in online
          transactions and to aid in tailoring the Clutch Services to your
          needs.
        </p>
        <p>
          By agreeing to Clutch’s Terms of Use, you agree to appoint Clutch as
          your agent for certain purposes, including (i) obtaining your medical
          information from pharmacies and pharmacy benefit managers (“PBMs”) in
          order to provide the Clutch Services and (ii) exercising your right to
          access your protected health information (“PHI”) pursuant to the
          Health Insurance Portability and Accountability Act (“HIPAA”). Please
          read the “Appointment of Clutch as Your Agent” section of the Terms of
          Use carefully in order to understand Clutch’s ability to collect your
          medical information on your behalf.
        </p>
        <h3>Clutch’s Use of Personal Information</h3>
        <p>
          Providing the Clutch Services. Clutch will use your PII only to
          address your requests for information, products or services or in
          connection with providing you the Clutch Services. Specifically,
          Clutch may use your PII to provide the following Clutch Services: (i)
          obtaining your medical information, including PHI, from your
          physicians, pharmacies, healthcare coverage providers and pharmacy
          benefit managers (collectively, “Healthcare Providers”), (ii) finding
          lower prices for your healthcare, or (iii) contacting Healthcare
          Providers, including physicians or pharmacies, on your behalf to
          request information necessary to provide the Clutch Services,
          including, but not limited to, your medical and healthcare coverage
          information, your medication history, and your billing information and
          history.
        </p>
        <p>
          Assisting You in Managing Your Healthcare. Clutch may use your PII to
          assist you in managing your prescriptions. To do so, Clutch may
          contact you by phone, email or text to (i) find out if you wish to
          request a prescription refill, (ii) remind you to request a
          prescription refill, (iii) confirm that a refill request has been
          submitted, or (iv) ask if you wish to transfer a prescription to a
          different pharmacy.{" "}
        </p>
        <p>
          Marketing by Clutch. Clutch may also use your PII to deliver
          advertisements and marketing communications to you that we believe may
          be of interest. These advertisements and marketing communications will
          be delivered solely by Clutch. Marketing communications that you
          receive from Clutch may include (i) advertisements for the Clutch
          Services, and (ii) advertisements for prescription drugs
        </p>
        <h3>Disclosure of Personal Information</h3>
        <p>
          Clutch will not sell, rent, license, or trade your PII with third
          parties for their own direct marketing use unless we receive your
          express consent to do so. Unless you give us permission to do so,
          Clutch will not share your PII other than as specified in this Privacy
          Policy. Clutch may, however, use your PII to find and offer to you
          coupons and discounts relevant to your healthcare that are offered by
          third parties.
        </p>
        <p>
          Because you have appointed Clutch as your agent pursuant to the Clutch
          Terms of Use, Clutch is authorized to act on your behalf in obtaining
          your PHI from your Healthcare Providers. As described in this Privacy
          Policy, Clutch is committed to safeguarding the privacy and security
          of your PII, including medical information, when we use, maintain or
          transmit that data. However, because Clutch is acting on your behalf,
          it is not a covered entity or business associate subject to HIPAA.
          Nevertheless, when Clutch obtains your medical information while
          acting on your behalf, we will maintain the privacy and security of
          that information in accordance with the terms of this Privacy Policy
          and consistent with the security standards of the HIPAA security
          regulations.
        </p>
        <h3>Disclosures Related to Providing the Clutch Services</h3>
        <p>
          You acknowledge that any and all information that you provide to
          Clutch may be disclosed to Healthcare Providers in order to provide
          the Clutch Services. Clutch is not responsible for protecting the
          privacy of your information when it is in the possession of a
          Healthcare Provider.
        </p>
        <p>
          Disclosures to Third Parties Assisting In Our Operations. Clutch may
          share your PII under confidentiality agreements with other companies
          that work with, or on behalf of, Clutch to provide products and
          services. These companies may use your PII to assist Clutch in its
          operations. However, these companies do not have any independent right
          to share this information.{" "}
        </p>
        <p>
          Disclosures Under Special Circumstances. We may provide information
          about you to respond to subpoenas, court orders, legal process or
          governmental regulations, or to establish or exercise our legal rights
          or defend against legal claims. We believe it is necessary to share
          information in order to investigate, prevent or take action regarding
          illegal activities, suspected fraud, situations involving potential
          threats to the physical safety of any person, or as otherwise required
          by law.
        </p>
        <p>
          Business Transfers. We may share your PII with other business entities
          in connection with the sale, assignment, merger or other transfer of
          all or a portion of Clutch’s business to such business entity. We will
          require any such successor business entity to honor the terms of this
          Privacy Policy.
        </p>
        <h3>Automatically Collected Information and Anonymous Information</h3>
        <p>
          Each time a visitor uses the Clutch Services, Clutch collects some
          information to improve the overall quality of the visitor’s online
          experience.
        </p>
        <p>
          Aggregated Data. Clutch collects aggregate queries for internal
          reporting and may also count, track, and aggregate the visitor's
          activity into Clutch’s analysis of general traffic-flow on the
          Websites. To these ends, Clutch may merge information about you into
          aggregated group data. In some cases, Clutch may remove personal
          identifiers from PII and maintain it in aggregate form that may later
          be combined with other information to generate anonymous, aggregated
          statistical information. Such anonymous, group data may be shared on
          an aggregated basis with Clutch’s affiliates, business partners,
          service providers and/or vendors; if it does so, Clutch will not
          disclose your individual identity.
        </p>
        <p>
          Web Server Logs and IP Addresses. An Internet Protocol (“IP”) address
          is a number that automatically identifies the computer/machine you
          have used to access the Internet. The IP address enables our server to
          send you the web pages that you want to visit, and it may disclose the
          server owned by your Internet Service Provider. Clutch may use IP
          addresses to conduct analyses and performance reviews and to
          administer the Clutch Services.{" "}
        </p>
        <p>
          Cookies and Web Beacons. Cookies are pieces of information that a
          website transfers to a user’s computer for purposes of storing
          information about a user’s preferences. Cookies in and of themselves
          do not personally identify users, although they do identify a user’s
          computer. Many websites use cookies as a standard practice to provide
          useful features when a user visits the website and most web browsers
          are set up to accept cookies. Clutch may use cookies to improve your
          online experience when using the Clutch Services. You can set your
          browser to refuse cookies, but some portions of the Clutch Services
          may not work properly if you refuse cookies. Some of Clutch’s web
          pages may use web beacons in conjunction with cookies to compile
          aggregate statistics about Clutch Services usage. A web beacon is an
          electronic image (also referred to as an “action tag,” “single-pixel,”
          or “clear GIF”) that is commonly used to track the traffic patterns of
          users from one web page to another in order to maximize web traffic
          flow and to otherwise analyze the effectiveness of the Clutch
          Services. Some web beacons may be unusable if you elect to reject
          their associated cookies. Clutch may also record the movement of
          Members and Visitors from page to page on the Website in order to
          improve the Clutch Services.
        </p>
        <h3>Referral/Links</h3>
        <p>
          The Clutch Services may contain links to third-party websites that may
          offer information of interest. This Privacy Policy does not apply to
          those websites, and Clutch recommends reviewing those websites’
          privacy policies individually.
        </p>
        <h3>Security</h3>
        <p>
          Clutch understands that storing our data in a secure manner is
          essential. Clutch stores PII and other data using industry standard
          physical, technical and administrative safeguards to secure data
          against foreseeable risks, such as unauthorized use, access,
          disclosure, destruction or modification. Although Clutch is neither a
          covered entity nor a business associate subject to HIPAA legal
          requirements, Clutch has implemented data security measures that are
          consistent with the standards of the HIPAA security regulations.
          Please note, however, that while Clutch has endeavored to create a
          secure and reliable website for users, the confidentiality of any
          communication or material transmitted to or from the Clutch Services
          or via e-mail cannot be guaranteed.
        </p>
        <h3>Your Role in Protecting Your Privacy</h3>
        <p>
          You are responsible for maintaining the confidentiality of your Clutch
          account password. Never share your Clutch password with anyone you
          don’t want accessing your account. You agree that you will provide
          access to your password only to insured spouses and/or dependents and
          you agree any user of your password is bound by the terms set forth in
          this Privacy Policy and the Terms of Use. It is your sole
          responsibility to inform Clutch of any need to deactivate a password.
        </p>
        <h3>Response to “Do Not Track” Signals</h3>
        <p>
          Some Internet browsers include the ability to transmit “Do Not Track”
          signals. Since uniform standards for “Do Not Track” signals have not
          yet been adopted, Clutch does not process or respond to “Do Not Track”
          signals.
        </p>
        <h3>Children's Privacy Protection</h3>
        <h4>Under Age 13</h4>
        <p>
          Clutch understands the importance of protecting children's privacy in
          the interactive online world. The Clutch Services, including the
          Mobile App, are not designed for, or intentionally targeted at,
          children under 13 years of age. It is not our policy to intentionally
          collect or maintain information about anyone under the age of 13. No
          one under the age of 13 should submit any PII to Clutch and/or the
          Mobile App.
        </p>
        <h4>Under Age 18</h4>
        <p>
          Minors under 18 years of age may have the PII that they have provided
          to Clutch through the Clutch Services, including the Mobile App,
          deleted by sending an email to{" "}
          <a href="mailto:privacy@clutch.health">privacy@clutch.health</a>{" "}
          requesting deletion. Please note that, while we make reasonable
          efforts to comply with such requests, deletion of your personal
          information does not ensure complete and comprehensive removal of that
          data from all systems.
        </p>
        <h3>Changes</h3>
        <p>
          At certain locations on the Mobile App where information about you may
          be requested, you are offered the opportunity to opt-out of receiving
          communications from Clutch. You may also review and request changes to
          your PII that Clutch has collected, including the removal of your PII
          from Clutch’s databases in order to prevent receipt of future
          communications or to halt receipt of the Clutch Services, using any of
          the following options:
        </p>
        <p>
          You can send your request via email to:{" "}
          <a href="mailto:privacy@clutch.health">privacy@clutch.health</a>
        </p>
        <p>You can mail your request to the following postal address:</p>
        <p>
          <strong>Clutch Health LLC</strong>
          <br />
          438 Porsche Ave <br />
          Atlanta, GA 30354 <br />
          Attn: Chief Privacy Officer
        </p>
        <h3>Policy Updates</h3>
        <p>
          This Privacy Policy may be revised from time to time as we add new
          features and services, as laws change, and as industry privacy and
          security best practices evolve. We display an effective date on the
          policy in the upper right corner of this Privacy Policy so that it
          will be easier for you to know when there has been a change. If we
          make any change to this Privacy Policy regarding use or disclosure of
          PII, we will provide advance notice on the Clutch Services and in the
          iTunes App Store where the Mobile App may be downloaded. Small changes
          or changes that do not significantly affect individual privacy
          interests may be made at any time and without prior notice.
        </p>
        <h3>Questions?</h3>
        <p>
          If you have any questions about this Privacy Policy or about Clutch’s
          handling of your information, please contact{" "}
          <a href="mailto:privacy@clutch.health">privacy@clutch.health</a> or
          (844) 979-9909.
        </p>
      </div>
    </>
  );
}

// style
const LinkText = styled.span`
  ${Theme.simplonNorm};
  display: inline-block;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  line-height: 21px;
  color: ${Theme.purple};
  margin-bottom: 15px;
  cursor: pointer;
  text-transform: uppercase;

  @media (min-width: ${Theme.breakpoints.md}) {
    ${Theme.openSans};
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-transform: none;
  }
`;
const IconBack = styled.img`
  display: inline-block;
  height: 9px;
  margin-right: 2px;
  transform: rotate(270deg);

  @media (min-width: ${Theme.breakpoints.md}) {
    heigt: 10px;
  }
`;
