import React, { useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import Theme from "../styles/Theme";
import { TOGGLE_FLASH } from "./FlashNotifier";
import { ReactComponent as Check } from "../static/icons/check.svg";
import bgRegular from "../static/images/pathways-bg.png";
import logo from "../static/images/clutch-logo.svg";
import mobileNav from "../static/icons/icon-mobile-nav.svg";
import { ReactComponent as IconClose } from "../static/icons/icon-close.svg";
import { ReactComponent as IconDash } from "../static/icons/nav-dash.svg";
import { ReactComponent as IconMyClutch } from "../static/icons/nav-myclutch.svg";
import { ReactComponent as IconAccount } from "../static/icons/nav-account.svg";
import { ReactComponent as IconSignOut } from "../static/icons/nav-signout.svg";

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

// prop types
Navigation.propTypes = {
  isDesktop: PropTypes.bool
};

export default function Navigation({ isDesktop }) {
  const [toggleFlash] = useMutation(TOGGLE_FLASH);
  const [logout, { client }] = useMutation(LOGOUT, {
    onError() {
      // this callback prevents apollo from throwing
      // ...unhandled exception on 400 status code
    },
    onCompleted() {
      // this clears the global cached error "hack"
      client.writeData({ data: { hasGraphError: false } });
    }
  });

  const handleLogout = () => {
    // ui updates immediately
    client.writeData({
      data: {
        currentUser: null,
        isLoggedIn: false
      }
    });
    toggleFlash({
      variables: {
        message: "You have been successfully signed out",
        icon: <Check />
      }
    });

    // request logout on server before clearing local access tokens
    // ...but clear the tokens regarless of success/failure from server
    logout().then(() => localStorage.clear());
  };

  const activeStyle = {
    color: Theme.orange,
    fontWeight: "bold",
    opacity: 1
  };

  const [showMobileNav, setShowMobileNav] = useState(false);

  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav);
  };

  return (
    <>
      {!isDesktop && (
        // mobile nav
        <>
          <Header>
            <Logo src={logo} alt="logo" />
            <NavToggle
              src={mobileNav}
              alt="nav toggle"
              onClick={toggleMobileNav}
            />
          </Header>
          {showMobileNav && <Backdrop onClick={toggleMobileNav} />}
          <NavPanel className={showMobileNav ? "show-nav" : ""}>
            <Close onClick={toggleMobileNav}>
              <IconClose />
            </Close>
            <MobileNav>
              <NavLink
                exact
                activeStyle={activeStyle}
                to="/"
                onClick={toggleMobileNav}
              >
                <IconDash />
                Dashboard
              </NavLink>
              <NavLink
                activeStyle={activeStyle}
                to="/my-clutch"
                onClick={toggleMobileNav}
              >
                <IconMyClutch />
                My Clutch
              </NavLink>
              <NavLink
                activeStyle={activeStyle}
                to="/account"
                onClick={toggleMobileNav}
              >
                <IconAccount />
                Account
              </NavLink>
              <SignOut onClick={handleLogout}>
                <IconSignOut />
                Sign Out
              </SignOut>
            </MobileNav>
          </NavPanel>
        </>
      )}
      {isDesktop && (
        // desktop nav
        <DesktopNavPanel>
          <Logo src={logo} alt="logo" />
          <DesktopNav>
            <NavLink exact activeStyle={activeStyle} to="/">
              <IconDash />
              Dashboard
            </NavLink>
            <NavLink activeStyle={activeStyle} to="/my-clutch">
              <IconMyClutch />
              My Clutch
            </NavLink>
            <NavLink activeStyle={activeStyle} to="/account">
              <IconAccount />
              Account
            </NavLink>
            <SignOut onClick={handleLogout}>
              <IconSignOut />
              Sign Out
            </SignOut>
          </DesktopNav>
        </DesktopNavPanel>
      )}
    </>
  );
}

// style
const Header = styled.div`
  background: ${Theme.textDark};
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 22px 16px;

  img {
    margin-bottom: 0;
  }
`;
const NavToggle = styled.img`
  width: 18px;
  margin-right: 4px;
  cursor: pointer;
`;
const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(83, 51, 156, 0.4);
`;
const NavPanel = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 326px;
  padding: 28px 25px 28px 28px;
  background: ${Theme.textDark};
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 100;
  transform: translateX(100%);
  transition: transform 200ms ease-in-out;
`;
const Close = styled.span`
  width: 14px;
  align-self: flex-end;
  cursor: pointer;
  color: white;
`;
const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  a {
    display: flex;
    font-size: 20px;
    line-height: 24px;
    margin: 18px 0;
    color: white;

    &:hover,
    &:focus {
      color: white;
      opacity: 0.8;
    }
  }

  svg {
    height: 24px;
    width: 24px;
    margin-right: 12px;
  }
`;
const DesktopNavPanel = styled.div`
  color: white;
  min-height: 100vh;
  width: 230px;
  background: url(${bgRegular}) no-repeat -179px -161px;
  background-color: ${Theme.textDark};
  padding: 27px 26px;
  flex-shrink: 0;
`;
const DesktopNav = styled.nav`
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    font-size: 20px;
    line-height: 24px;
    margin: 13px 0;
    color: white;
    opacity: 0.8;

    &:hover,
    &:focus {
      color: white;
      opacity: 0.6;
    }
  }

  svg {
    height: 24px;
    width: 24px;
    margin-right: 12px;
  }
`;
const Logo = styled.img`
  width: 80px;
  margin-bottom: 20px;
  pointer-events: none;
`;
const SignOut = styled.a`
  cursor: pointer;
`;
