import lowerCase from "lodash.lowercase";
import startCase from "lodash.startcase";

export const titleCase = string => startCase(lowerCase(string));

export function runLoginActions(
  apolloClient,
  mutationResponse,
  skipCacheActions = false
) {
  localStorage.setItem("accessToken", mutationResponse.accessToken);
  localStorage.setItem("refreshToken", mutationResponse.refreshToken);
  if (!skipCacheActions) {
    apolloClient.writeData({
      data: {
        currentUser: mutationResponse.user,
        isLoggedIn: true,
        // this clears the global cached error "hack"
        hasGraphError: false
      }
    });
  }
}
