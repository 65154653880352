import React from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { Formik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import * as yup from "yup";
import Button from "../components/Button";
import ChangePhoneInput from "../components/ChangePhoneInput";
import Error from "../components/Error";
import Fieldset from "../components/FormFields/Fieldset";
import Input from "../components/FormFields/Input";
import Loader from "../components/Loader";
import logo from "../static/images/clutch-logo.svg";
import MutationError from "../components/MutationError";
import Theme from "../styles/Theme";
import { TOGGLE_FLASH } from "../components/FlashNotifier";
import { ReactComponent as Check } from "../static/icons/check.svg";
import {
  Header,
  Heading,
  Intro,
  LoaderWrap,
  LoggedOutPageCard,
  Logo,
  Wrapper
} from "./CreateAccountPage";

const VERIFY_PHONE = gql`
  mutation VerifyPhone($verificationCode: String!) {
    verifyPhone(verificationCode: $verificationCode) {
      id
      flags {
        mustVerifyPhone
      }
    }
  }
`;

const RESEND_SMS = gql`
  mutation ResendPhoneVerification {
    resendPhoneVerification
  }
`;

export default function VerifyPhonePage() {
  const [toggleFlash] = useMutation(TOGGLE_FLASH);

  const [verifyPhone, { loading, error, client }] = useMutation(VERIFY_PHONE, {
    onError() {
      // this callback prevents apollo from throwing
      // ...unhandled exception on 400 status code
    },
    onCompleted() {
      // this callback clears the global cached error "hack"
      client.writeData({
        data: {
          hasGraphError: false
        }
      });
    }
  });

  const [resendPhoneVerification, { loading: reRequesting }] = useMutation(
    RESEND_SMS,
    {
      onError() {
        // this callback prevents apollo from throwing
        // ...unhandled exception on 400 status code
      },
      onCompleted() {
        // pop a snackbar
        toggleFlash({
          variables: {
            message: "New verification code sent",
            icon: <Check />
          }
        });
        // this clears the global cached error "hack"
        client.writeData({ data: { hasGraphError: false } });
      }
    }
  );

  const handleResendVerification = e => {
    e.preventDefault();
    resendPhoneVerification();
  };

  return (
    <Wrapper>
      <Header>
        <Logo src={logo} alt="logo" />
      </Header>

      <LoggedOutPageCard>
        <Heading>Enter verification code</Heading>
        <Intro>
          Please enter the code we sent below to verify your mobile number.
        </Intro>

        <ChangePhoneWrap>
          <ChangePhoneInput />
        </ChangePhoneWrap>

        <Formik
          initialValues={{ verificationCode: "" }}
          onSubmit={async values => verifyPhone({ variables: values })}
          validationSchema={yup.object().shape({
            verificationCode: yup.string().required("Required")
          })}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Fieldset>
                  <div>
                    <Input
                      label="Verification Code"
                      name="verificationCode"
                      value={values.verificationCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={
                        touched.verificationCode && !!errors.verificationCode
                      }
                    />
                    {errors.verificationCode && touched.verificationCode && (
                      <Error inputError>{errors.verificationCode}</Error>
                    )}
                  </div>
                </Fieldset>
                <Button
                  type="submit"
                  size="full"
                  disabled={isSubmitting || loading || !values.verificationCode}
                >
                  Verify Mobile Number
                </Button>
              </form>
            );
          }}
        </Formik>

        {loading && (
          <LoaderWrap>
            <Loader />
          </LoaderWrap>
        )}
        {error && <MutationError error={error} />}
      </LoggedOutPageCard>

      <Footer>
        <span>Didn't receive a text from us?</span>
        <FakeLink onClick={handleResendVerification}>
          Resend Verification Code
        </FakeLink>
        {reRequesting && <Loader size={24} color="alt" thickness={4} />}
      </Footer>
    </Wrapper>
  );
}

// style
const ChangePhoneWrap = styled.div`
  margin-bottom: 12px;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5.6rem;
  color: ${Theme.textLight};

  & > * {
    margin: 0 4px;
  }
`;
const FakeLink = styled.span`
  cursor: pointer;
  color: ${Theme.orange};
  &:hover,
  &:focus {
    color: ${Theme.orangeHover};
  }
`;
