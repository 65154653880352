import React, { useState, useEffect } from "react";
import get from "lodash.get";
import gql from "graphql-tag";
import t from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import Theme, { FadeIn } from "../../styles/Theme";
import { useMediaQuery } from "@material-ui/core";
import { useParams } from "react-router-dom";
import iconSort from "../../static/icons/icon-sort.svg";
import iconNext from "../../static/icons/icon-next.svg";
import EmptyTable from "../EmptyTable";
import Card from "../Card";
import Loader from "../../components/Loader";
import MutationError from "../../components/MutationError";
import PharmacyCard from "./PharmacyCard";
import PharmacyList from "./PharmacyList";
import PrescriptionStatus from "../PrescriptionStatus";
import ZipCodeSearch from "./ZipCodeSearch";
import { useGeolocation } from "../../utils/useGeolocation";

const PRESCRIPTION_PRICING = gql`
  query PrescriptionPricing(
    $id: ID!
    $location: LocationInput
    $zipCode: String
    $radius: Int
  ) {
    prescriptionPricing(
      prescriptionId: $id
      location: $location
      zipCode: $zipCode
      radius: $radius
    ) {
      genericEquivalent
      pharmacyPrices {
        name
        price
        locations {
          address {
            id
            city
            state
            street1
            street2
            zipCode
            lat
            long
          }
          distance
          npi
          phone
        }
      }
    }
  }
`;

// prop types
SinglePrescription.propTypes = {
  prescriptions: t.arrayOf(
    t.shape({
      id: t.string.isRequired,
      doseForm: t.string,
      doseStrength: t.string,
      quantity: t.number.isRequired,
      drugName: t.string.isRequired,
      genericDrugName: t.string,
      new: t.bool,
      state: t.oneOf(["unprocessed", "transferred", "filled", "reversed"]),
      instructions: t.string.isRequired,
      refillsRemaining: t.number.isRequired,
      doctor: t.shape({
        id: t.string.isRequired,
        firstName: t.string.isRequired,
        lastName: t.string.isRequired,
        phone: t.string
      }).isRequired,
      pharmacy: t.shape({
        id: t.string,
        address: t.object.isRequired,
        pharmacy: t.string.isRequired,
        phoneNumber: t.string.isRequired,
        price: t.string
      })
    })
  ).isRequired
};

export default function SinglePrescription({ prescriptions }) {
  /**
   * * Dynamic routing:
   * we receive all prescriptions from my-clutch page,
   * and pull out the match from the url...
   * if the url has no match, we redirect to my-clutch
   */
  let { prescriptionURI } = useParams();
  const prescription = prescriptions.find(
    prescription => prescription.id === prescriptionURI.split("-")[0]
  );

  //setup
  const {
    latitude,
    longitude,
    initializing: locInit,
    error: locError
  } = useGeolocation();
  const [mobileInstructions, setMobileInstructions] = useState(false);
  const isDesktop = useMediaQuery(`(min-width: ${Theme.breakpoints.md})`);

  const [geoSearch, { loading, data, error }] = useLazyQuery(
    PRESCRIPTION_PRICING,
    {
      onCompleted() {
        setInitialSearchComplete(true);
      }
    }
  );
  const [initialSearchComplete, setInitialSearchComplete] = useState(false);

  const isGeneric = get(data, "prescriptionPricing.genericEquivalent", false);
  const pharmacyTree = get(data, "prescriptionPricing.pharmacyPrices", []);

  // run geosearch when location becomes available
  useEffect(() => {
    if (latitude && longitude)
      geoSearch({
        variables: {
          id: prescription.id,
          location: { latitude, longitude },
          radius: 5
        }
      });
  }, [latitude, longitude, geoSearch, prescription]);

  // case-by-case empty result message
  let emptyMessage =
    "No pharmacies found. Try entering your zip code and radius to search for pharmacies near you.";
  if (locError)
    emptyMessage =
      "Please enter a zip code and radius to search for pharmacies near you.";
  if (locError && data && pharmacyTree.length === 0)
    emptyMessage =
      "No pharmacies found. Try expanding your radius, or check your zip code.";

  // render
  if (!prescription) return <Redirect to="/my-clutch" />;

  return (
    <FadeIn>
      <Wrapper>
        {!isDesktop && (
          <>
            {!mobileInstructions && (
              <>
                <Link to="/my-clutch">
                  <LinkText>
                    <IconBack src={iconSort} /> My Clutch
                  </LinkText>
                </Link>
                <Status>
                  <PrescriptionStatus status={prescription.state} />
                </Status>
                <h2 style={{ marginBottom: 4 }}>{prescription.drugName}</h2>
                <div style={{ marginBottom: 28, color: "#2f1d57" }}>
                  {prescription.genericDrugName}
                </div>
                <Row>
                  <Label>Dosage</Label>
                  <Value>{prescription.doseStrength}</Value>
                </Row>
                <Row>
                  <Label>Quantity</Label>
                  <Value>
                    {prescription.quantity} {prescription.doseForm}
                  </Value>
                </Row>
                <Row>
                  <Label>Refills</Label>
                  <Value>{prescription.refillsRemaining}</Value>
                </Row>
                <Row>
                  <Label>Authorized by</Label>
                  <Value>
                    <AuthorizedBy>
                      Dr. {prescription.doctor.firstName}{" "}
                      {prescription.doctor.lastName}
                    </AuthorizedBy>
                  </Value>
                </Row>
                <Row
                  style={{ cursor: "pointer" }}
                  onClick={() => setMobileInstructions(!mobileInstructions)}
                >
                  <Instructions>Doctor&apos;s Instructions</Instructions>
                  <IconInstructions src={iconNext} />
                </Row>

                {prescription.pharmacy ? (
                  <>
                    {prescription.state === "reversed" ? (
                      <StyledEmptyTable
                        title="Pharmacy"
                        message="Your prescription was not picked up within 30 days of sending it to the pharmacy, and has been put back on the shelf. Please contact your physician for a new prescription if this was done in error."
                      />
                    ) : (
                      <PharmacyCard pharmacy={prescription.pharmacy} />
                    )}
                  </>
                ) : (
                  <>
                    {// location denied or unavailable
                    (locError ||
                      //no results
                      (!pharmacyTree.length && !locInit && !loading) ||
                      //location search successful, show to give ability to change location/radius
                      initialSearchComplete) && (
                      <ZipCodeSearch
                        geoSearch={geoSearch}
                        id={prescription.id}
                        loading={loading}
                      />
                    )}
                    {!pharmacyTree.length && (
                      <StyledEmptyTable
                        title="Pharmacies"
                        message={
                          <>
                            {emptyMessage}
                            {error && <MutationError error={error} />}
                          </>
                        }
                        loadingContent={
                          locInit || loading ? (
                            <Loading>
                              <Loader size={24} thickness={4} />
                              {locInit && "Getting your current location..."}
                              {loading &&
                                "Searching for pharmacies close to you..."}
                            </Loading>
                          ) : null
                        }
                      />
                    )}
                    {!!pharmacyTree.length && (
                      <PharmacyList
                        isGeneric={isGeneric}
                        pharmacies={pharmacyTree}
                        prescription={prescription}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {mobileInstructions && (
              <FadeIn>
                <LinkText onClick={() => setMobileInstructions(false)}>
                  <IconBack src={iconSort} /> {prescription.drugName}{" "}
                  Prescription
                </LinkText>
                <div style={{ margin: "20px 0" }}>
                  <h3>Doctor&apos;s Instructions</h3>
                  <div>{prescription.instructions}</div>
                </div>
              </FadeIn>
            )}
          </>
        )}
        {isDesktop && (
          <>
            <Link to="/my-clutch">
              <LinkText>
                <IconBack src={iconSort} /> My Clutch
              </LinkText>
            </Link>
            <h1>{prescription.drugName}</h1>
            <div style={{ marginBottom: 28 }}>
              {prescription.genericDrugName}
            </div>
            <InnerWrapper>
              <div style={{ flexBasis: "640px" }}>
                <Card style={{ padding: 0 }}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Fill Status</th>
                        <th>Dosage</th>
                        <th>Quantity</th>
                        <th>Refills</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <PrescriptionStatus status={prescription.state} />
                        </td>
                        <td>{prescription.doseStrength}</td>
                        <td>
                          {prescription.quantity} {prescription.doseForm}
                        </td>
                        <td>{prescription.refillsRemaining} refills</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card>

                {prescription.pharmacy ? (
                  <>
                    {prescription.state === "reversed" ? (
                      <StyledEmptyTable
                        title="Pharmacy"
                        message="Your prescription was not picked up within 30 days of sending it to the pharmacy, and has been put back on the shelf. Please contact your physician for a new prescription if this was done in error."
                      />
                    ) : (
                      <PharmacyCard pharmacy={prescription.pharmacy} />
                    )}
                  </>
                ) : (
                  <>
                    {// location denied or unavailable
                    (locError ||
                      //no results
                      (!pharmacyTree.length && !locInit && !loading) ||
                      //location search successful, show to give ability to change location/radius
                      initialSearchComplete) && (
                      <ZipCodeSearch
                        geoSearch={geoSearch}
                        id={prescription.id}
                        loading={loading}
                      />
                    )}
                    {!pharmacyTree.length && (
                      <StyledEmptyTable
                        title="Pharmacies"
                        message={
                          <>
                            {emptyMessage}
                            {error && <MutationError error={error} />}
                          </>
                        }
                        loadingContent={
                          locInit || loading ? (
                            <Loading>
                              <Loader size={24} thickness={4} />
                              {locInit && "Getting your current location..."}
                              {loading &&
                                "Searching for pharmacies close to you..."}
                            </Loading>
                          ) : null
                        }
                      />
                    )}
                    {!!pharmacyTree.length && (
                      <PharmacyList
                        isGeneric={isGeneric}
                        pharmacies={pharmacyTree}
                        prescription={prescription}
                      />
                    )}
                  </>
                )}
              </div>
              <StyledCard>
                <div style={{ borderBottom: "1px solid #f5f5f5" }}>
                  <SmallText>Authorized by</SmallText>
                  <h4 style={{ marginBottom: 0 }}>
                    Dr. {prescription.doctor.firstName}{" "}
                    {prescription.doctor.lastName}
                  </h4>
                </div>

                <div>
                  <SmallText>Instructions</SmallText>
                  <div>{prescription.instructions}</div>
                </div>
              </StyledCard>
            </InnerWrapper>
          </>
        )}
      </Wrapper>
    </FadeIn>
  );
}

// style
const Wrapper = styled.div`
  display: block;
  position: relative;
`;
const StyledEmptyTable = styled(EmptyTable)`
  ${Theme.fullBleedChild}
  margin-top: 33px;
  box-shadow: none;

  & > div:first-child {
    padding: 25px 16px;
  }

  @media (min-width: ${Theme.breakpoints.sm}) {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    & > div:first-child {
      padding: 12px 16px;
    }
  }
`;
const InnerWrapper = styled.div`
  display: flex;
`;
const LinkText = styled.span`
  ${Theme.simplonNorm};
  display: inline-block;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  line-height: 21px;
  color: ${Theme.purple};
  margin-bottom: 15px;
  cursor: pointer;
  text-transform: uppercase;

  @media (min-width: ${Theme.breakpoints.md}) {
    ${Theme.openSans};
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-transform: none;
  }
`;
const IconBack = styled.img`
  display: inline-block;
  height: 9px;
  margin-right: 2px;
  transform: rotate(270deg);

  @media (min-width: ${Theme.breakpoints.md}) {
    heigt: 10px;
  }
`;
const Status = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const Row = styled.div`
  ${Theme.fullBleedChild}
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${Theme.uiLighter};

  @media (min-width: ${Theme.breakpoints.sm}) {
    padding: 16px 0;
  }
`;
const Label = styled.span`
  ${Theme.smallText};
  color: ${Theme.textLight};
`;
const Value = styled.h4`
  margin-bottom: 0;
`;
const AuthorizedBy = styled.span`
  color: ${Theme.orange};
  font-size: 20px;
  line-height: 24px;
`;
const Instructions = styled.span`
  color: ${Theme.orange};
`;
const IconInstructions = styled.img`
  color: ${Theme.orange};
  fill: ${Theme.orange};
  display: inline-block;
  width: 16px;
  transform: rotate(90deg);
`;
const StyledCard = styled(Card)`
  padding: 0;
  flex-basis: 346px;
  margin-left: 33px;

  > div {
    padding: 21px;
  }
`;
const SmallText = styled.span`
  ${Theme.smallText};
  display: inline-block;
  margin-bottom: 10px;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead {
    background: ${Theme.windowBackground};
  }
  th {
    ${Theme.smallText}
    text-align: left;
    padding: 12px 16px;
  }
  td {
    padding: 24px 16px;
  }
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Theme.moderat};
  color: ${Theme.textLight};
  padding: 21px 16px;

  & > div:first-child {
    margin-right: 10px;
  }
`;
