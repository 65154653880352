import React from "react";
import gql from "graphql-tag";
import t from "prop-types";
import { Formik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import * as yup from "yup";
import Button from "../Button";
import Error from "../Error";
import Fieldset from "../FormFields/Fieldset";
import PhoneField, { phoneRegExp } from "../FormFields/PhoneField";
import Loader from "../Loader";
import MutationError from "../MutationError";
import { runLoginActions } from "../../utils/helpers";
import {
  LoaderWrap,
  LoggedOutPageCard,
  Heading,
  Intro
} from "../../pages/CreateAccountPage";

VerifyPhone.propTypes = {
  state: t.shape({
    uiStep: t.oneOf(["findAccount", "createUser", "verifyPhone"]).isRequired,
    userDetails: t.shape({
      dateOfBirth: t.instanceOf(Date),
      email: t.string,
      firstName: t.string,
      lastName: t.string,
      password: t.string,
      phone: t.string,
      registrationCode: t.string,
      sex: t.oneOf(["", "male", "female"])
    }).isRequired
  })
};

const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $password: String!
    $phone: String!
    $firstName: String!
    $lastName: String!
    $dateOfBirth: Date!
    $registrationCode: String!
    $sex: Sex!
  ) {
    createUser(
      email: $email
      password: $password
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      registrationCode: $registrationCode
      sex: $sex
    ) {
      accessToken
      refreshToken
      user {
        id
        phone
        flags {
          mustVerifyPhone
        }
      }
    }
  }
`;

export default function VerifyPhone({ state }) {
  const [createUser, { loading, error, client }] = useMutation(CREATE_USER, {
    onError() {
      // this callback prevents apollo from throwing
      // ...unhandled exception on 400 status code
    },
    onCompleted({ createUser: response }) {
      runLoginActions(client, response);
    }
  });

  return (
    <LoggedOutPageCard>
      <Heading>Enter mobile number</Heading>
      <Intro>
        To verify your identity, please enter your mobile number below to
        recieve a verification code.
      </Intro>
      <Formik
        initialValues={{
          phone: ""
        }}
        onSubmit={async values => {
          createUser({
            variables: {
              ...state.userDetails,
              ...values
            }
          });
        }}
        validationSchema={yup.object().shape({
          phone: yup
            .string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("Required")
        })}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Fieldset>
                <div>
                  <PhoneField
                    name="phone"
                    label="Mobile Phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.phone && !!errors.phone}
                  />
                  {errors.phone && touched.phone && (
                    <Error inputError>{errors.phone}</Error>
                  )}
                </div>
              </Fieldset>
              <Button
                type="submit"
                size="full"
                disabled={
                  isSubmitting ||
                  loading ||
                  Object.values(values).some(value => !value)
                }
              >
                Send Verification Code
              </Button>
            </form>
          );
        }}
      </Formik>

      {loading && (
        <LoaderWrap>
          <Loader />
        </LoaderWrap>
      )}
      {error && <MutationError error={error} />}
    </LoggedOutPageCard>
  );
}
