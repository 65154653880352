import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Theme from "../../styles/Theme";
import Input from "./Input";
import Switch from "./Switch";

// prop types
PasswordField.propTypes = {
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string
};

export default function PasswordField({
  onChange,
  hasError,
  value,
  label = "Password",
  ...props
}) {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleClickShowPassword = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Wrapper>
      <InputWrap>
        <Input
          name="password"
          value={value}
          onChange={onChange}
          label={label}
          type={isVisible ? "text" : "password"}
          hasError={hasError}
          {...props}
        />
      </InputWrap>
      <SwitchWrap>
        <Label>Show</Label>
        <Switch value={isVisible} onChange={handleClickShowPassword} />
      </SwitchWrap>
    </Wrapper>
  );
}

// style
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const InputWrap = styled.div`
  flex-grow: 1;
`;
const SwitchWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  margin-left: 8px;
  font-size: 7px;
  color: ${Theme.textMedium};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;
