import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/core/styles";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { typeDefs, resolvers } from "./utils/localSchema";
import { muiTheme } from "./styles/Theme";
import { TOGGLE_FLASH } from "./components/FlashNotifier";
import { ReactComponent as SignOut } from "./static/icons/nav-signout.svg";

// Apollo setup
const cache = new InMemoryCache();
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT
});
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (networkError) {
    // log network errors
    console.error("[Network error]: ", networkError);
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      // log gql error(s)
      console.error("[GraphQL error]: ", error);

      // unauthenticated
      if (error.extensions.code === "UNAUTHENTICATED") {
        localStorage.clear();
        client.writeData({ data: { currentUser: null, isLoggedIn: false } });
        client.mutate({
          mutation: TOGGLE_FLASH,
          variables: {
            message: "Your session has expired. Please log in again.",
            icon: <SignOut />
          }
        });
      }

      // cache error (mutation only)
      if (operation.query.definitions[0].operation === "mutation") {
        client.writeData({
          data: {
            hasGraphError: true,
            currentGraphError: error.message
          }
        });
      }
    });
  }
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : ""
    }
  };
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(errorLink).concat(httpLink),
  resolvers,
  typeDefs
});

// initialize cache
cache.writeData({
  data: {
    isLoggedIn: !!localStorage.getItem("accessToken"),
    flashMessages: []
  }
});

// Main
ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
