import React from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import * as yup from "yup";
import Button from "../components/Button";
import Error from "../components/Error";
import Fieldset from "../components/FormFields/Fieldset";
import Input from "../components/FormFields/Input";
import Loader from "../components/Loader";
import logo from "../static/images/clutch-logo.svg";
import MutationError from "../components/MutationError";
import Theme from "../styles/Theme";
import { runLoginActions } from "../utils/helpers";
import {
  Wrapper,
  Header,
  Logo,
  Heading,
  Intro,
  Footer,
  LoggedOutPageCard,
  LoaderWrap
} from "./CreateAccountPage";

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
      user {
        id
        phone
        flags {
          mustVerifyPhone
          mustUpdatePassword
        }
      }
    }
  }
`;

export default function LoginPage() {
  const [login, { loading, error, client }] = useMutation(LOGIN, {
    onError() {
      // this callback prevents apollo from throwing
      // ...unhandled exception on 400 status code
    },
    onCompleted({ login: response }) {
      runLoginActions(client, response);
    }
  });

  return (
    <Wrapper>
      <Header>
        <Logo src={logo} alt="logo" />
      </Header>

      <LoggedOutPageCard>
        <Heading>Glad to see you!</Heading>
        <Intro>Please enter your email and password to log in.</Intro>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={async values => login({ variables: values })}
          validationSchema={yup.object().shape({
            email: yup
              .string()
              .email("Must be a valid email")
              .required("Required"),
            password: yup.string().required("Required")
          })}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Fieldset>
                  <div>
                    <Input
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.email && !!errors.email}
                    />
                    {touched.email && errors.email && (
                      <Error inputError>{errors.email}</Error>
                    )}
                  </div>

                  <div>
                    <Input
                      type="password"
                      name="password"
                      label="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.password && !!errors.password}
                    />
                    {touched.password && errors.password && (
                      <Error inputError>{errors.password}</Error>
                    )}
                  </div>
                </Fieldset>

                <ForgotPass>
                  <Link to="/forgot-password">Forgot password?</Link>
                </ForgotPass>

                <Button type="submit" size="full" disabled={isSubmitting}>
                  Submit
                </Button>
              </form>
            );
          }}
        </Formik>

        {loading && (
          <LoaderWrap>
            <Loader />
          </LoaderWrap>
        )}
        {error && <MutationError error={error} />}
      </LoggedOutPageCard>

      <Footer>
        Don't have an account? <Link to="/create-account">Create Account</Link>
      </Footer>
    </Wrapper>
  );
}

const ForgotPass = styled.section`
  display: flex;
  justify-content: flex-end;
  padding: 12px 0 20px;
  font-size: 1.2rem;
  line-height: 1.6rem;

  a {
    color: ${Theme.orange};
    &:hover,
    &:focus {
      color: ${Theme.orangeHover};
    }
  }
`;
