import gql from "graphql-tag";
import { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { runLoginActions } from "./helpers";

/**
 * * Silent Auth hook has two purposes:
 * 1. Try to get a new access token immediately
 *    if the app is loaded/refreshed with a refresh token already present
 *    ...(this will authenticate a user who exits the app then returns after
 *    the accessToken expires, while the refresh token is still valid)
 * 2. Preemptively refresh the access token periodically
 *    ...(this will prevent a persistent user / open tab from kicking the user
 *    due to expired token when their refresh token is still valid)
 *
 * * Notes:
 *   refresh token valid 3hrs, access token valid 1hr, according to backend devs
 *   ...(the tokens themselves do not include expiration data so we cannot verify)
 *   our global error handling of unauthenticated requests in Apollo errorLink
 *   ...will satisfy failures of this mutation, so we don't handle errors here
 */

const REFRESH = gql`
  mutation RefreshToken($accessToken: String!, $refreshToken: String!) {
    refreshToken(accessToken: $accessToken, refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export function useSilentAuth() {
  const [refresh, { client }] = useMutation(REFRESH, {
    onError() {
      // this callback prevents apollo from throwing
      // ...unhandled exception on 400 status code
    },
    onCompleted({ refreshToken: response }) {
      runLoginActions(client, response, true);
      client.writeData({
        data: {
          // this clears the global cached error "hack"
          hasGraphError: false
        }
      });
    }
  });

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      let interval = setInterval(
        () =>
          refresh({
            variables: {
              refreshToken,
              accessToken: localStorage.getItem("accessToken")
            }
          }),
        600000 // 10 mins
      );
      return () => clearInterval(interval);
    }
  }, [refresh]);
}
