import { createGlobalStyle } from "styled-components";
import SidebarBg from "../static/images/pathways-bg.png";
import Logo from "../static/images/clutch-logo.svg";
import Theme from "./Theme";

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 62.5%;
  }
  body {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: ${Theme.textNormal};
    font-family: ${Theme.moderat};
  }
  h1,h2,h3,h4 {
    color: ${Theme.textDark};
    font-weight: bold;
    margin: 0 0 8px;
  }
  h1 {
    font-size: 3.6rem;
    line-height: 4.3rem;
  }
  h2 {
    font-size: 2.4rem;
    line-height: 2.9rem;
    letter-spacing: 0.5px;
  }
  h3 {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  h4 {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  h5 {
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-family: ${Theme.openSans};
    font-weight: 400;
  }
  a {
    color: ${Theme.purple};
    text-decoration: none;
    outline: none;
    transition: ${Theme.transition};
    &:hover,
    &:focus {
      color: ${Theme.purpleHover};
    }
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .show-nav {
    transform: translateX(0);
  }
  /**
  * React Styleguidist Custom Styles
  * TODO: move to separate file
  */
  #rsg-root code {
    background: #f5f5f5;
    padding: 3px 6px;
    border-radius: 3px;
  }
  h1.rsg--heading-14 {
    font-family: ${Theme.moderat} !important;
    font-weight: 700;
    color: ${Theme.textDark};
    margin: 30px 0 20px;
    line-height: 1;
  }
  #rsg-root h2.rsg--heading-14 {
    font-size: 24px;
    font-family: ${Theme.moderat} !important;
    font-weight: 700;
    color: ${Theme.textDark};
  }
  .rsg--sidebar-4 {
    background-image: url(${SidebarBg});
    background-size: cover;
    width: 260px;
    padding-right: 10px;
  }
  #rsg-root header h1 {
    color: transparent;
    background-image: url(${Logo});
    background-size: 80px;
    background-repeat: no-repeat;
    height: 32px;
    margin: 1rem 0 0;
  }
  #rsg-root .rsg--sidebar-4 nav ul li a {
    color: #fff !important;
    ${Theme.moderat} !important;
    font-size: 20px;
    line-height: 30px;

    &:link,
    &:visited {
      color: #fff !important;
    }
    &:hover,
    &:focus {
      color: ${Theme.orange} !important;
    }
  }
  #rsg-root .rsg--sidebar-4 nav > ul > li > a {
    color: ${Theme.orange} !important;
    font-weight: 700;

    &:link,
    &:visited {
      color: ${Theme.orange} !important;
    }
  }
  #rsg-root .rsg--isActive-32 {
    border-bottom: 2px solid ${Theme.orange};

    &:hover,
    &:focus {
      color: ${Theme.orange} !important;
      outline: none;
    }
  }
`;

export default GlobalStyles;
