import React from "react";
import styled from "styled-components";
import t from "prop-types";
import * as yup from "yup";
import Theme from "../../styles/Theme";
import { ReactComponent as Lower } from "../../static/icons/password-lower.svg";
import { ReactComponent as Upper } from "../../static/icons/password-upper.svg";
import { ReactComponent as Special } from "../../static/icons/password-special.svg";
import { ReactComponent as Length } from "../../static/icons/password-length.svg";

PasswordConstraints.propTypes = {
  value: t.string.isRequired
};

const lengthErr = "Password is too short",
  lowerErr = "Must have at least one lowercase letter",
  upperErr = "Must have at least one uppercase letter",
  specialErr = "Must use a special character";

export const passwordSchema = yup
  .string()
  .min(8, lengthErr)
  .matches(/(?=.*[a-z])/, lowerErr)
  .matches(/(?=.*[A-Z])/, upperErr)
  .matches(/[-!"#$%&'()*+,./:;<=>?@[^_`{|}~\]\\]/, specialErr);

export default function PasswordConstraints({ value }) {
  const errors = [];

  try {
    passwordSchema.validateSync(value, {
      abortEarly: false
    });
  } catch ({ errors: incomingErrors }) {
    incomingErrors.forEach(err => errors.push(err));
  }

  return (
    <Wrapper>
      <Rule satisfied={!errors.includes(lowerErr)}>
        <Lower />
        <span>lowercase</span>
      </Rule>
      <Rule satisfied={!errors.includes(upperErr)}>
        <Upper />
        <span>UPPERCASE</span>
      </Rule>
      <Rule satisfied={!errors.includes(specialErr)}>
        <Special />
        <span>Special</span>
      </Rule>
      <Rule satisfied={!errors.includes(lengthErr)}>
        <Length />
        <span>Characters</span>
      </Rule>
    </Wrapper>
  );
}

// style
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 14px;
`;

const Rule = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 51px;
  width: 51px;
  color: ${Theme.textLight};

  span {
    display: block;
    font-size: 10px;
    line-height: 1;
  }

  ${props => props.satisfied && `color: ${Theme.purple};`}
`;
