import React from "react";
import t from "prop-types";
import { Input as MuiInput, InputLabel } from "@material-ui/core";
import Theme from "../../styles/Theme";

Input.propTypes = {
  label: t.string.isRequired,
  name: t.string.isRequired,
  onChange: t.func.isRequired,
  hasError: t.bool,
  onBlur: t.func,
  placeholder: t.string,
  value: t.string
};

export default function Input({ name, label, hasError, ...props }) {
  return (
    <>
      <InputLabel
        shrink={false}
        htmlFor={name}
        style={hasError ? { color: Theme.orange } : {}}
      >
        {label}
      </InputLabel>
      <MuiInput name={name} {...props} />
    </>
  );
}
