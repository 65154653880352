import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import Theme from "../styles/Theme";
import { Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: Theme.uiWhite,
    width: "100vw",
    margin: 0
  },
  paper: {
    display: "block",
    position: "absolute",
    width: 400,
    backgroundColor: Theme.uiWhite
  },
  paperActive: {
    display: "block !important"
  }
}));

// prop types
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

export default function Modal({ onClose, children }) {
  const classes = useStyles();

  return (
    <>
      {createPortal(
        <>
          <Backdrop
            className={classes.backdrop}
            open={true}
            onClick={onClose}
          ></Backdrop>
          <Wrapper className={classes.paperActive} onClose={onClose}>
            {children}
          </Wrapper>
        </>,
        document.body
      )}
    </>
  );
}

// style
const Wrapper = styled.div`
  background: ${Theme.uiWhite};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  overflow: hidden;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;

  @media (min-width: 349px) {
    min-width: 349px;
  }
`;
