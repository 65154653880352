import React from "react";
import t from "prop-types";
import { InputLabel } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Theme from "../../styles/Theme";
import { useFormikContext } from "formik";

Datepicker.propTypes = {
  label: t.string.isRequired,
  name: t.string.isRequired,
  hasError: t.bool,
  onBlur: t.func,
  value: t.oneOfType([t.string, t.instanceOf(Date)])
};

export default function Datepicker({ name, label, hasError, value, ...props }) {
  const { setFieldValue } = useFormikContext();

  // invalid is an internal distinction to this component
  // ... whereas hasError is determined by Formik validation
  // ... we will treat both the same for display
  const invalid = !!value && typeof value !== "string";

  return (
    <>
      <InputLabel
        shrink={false}
        htmlFor={name}
        style={hasError || invalid ? { color: Theme.orange } : {}}
      >
        {label}
      </InputLabel>
      <KeyboardDatePicker
        disableFuture
        name={name}
        value={value}
        onChange={value => setFieldValue("dateOfBirth", value)}
        format="MM/dd/yyyy"
        fullWidth
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        {...props}
        // we only have birthday pickers in this app,
        // ...so the following props are appropriate
        openTo="year"
        views={["year", "month", "date"]}
      />
    </>
  );
}
