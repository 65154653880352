import React from "react";
import t from "prop-types";
import { Link } from "react-router-dom";
import Card from "../Card";
import Button from "../Button";
import icon from "../../static/icons/icon-med.svg";
import Theme from "../../styles/Theme";
import styled from "styled-components";

// prop types
Updates.propTypes = {
  prescriptions: t.array.isRequired
};

export default function Updates({ prescriptions }) {
  const newPrescriptions = prescriptions.filter(item => item.new === true);

  return (
    <Wrapper>
      <Heading>Updates</Heading>

      {newPrescriptions.map(function(item, index) {
        return (
          <Card key={index} style={{ marginBottom: 28 }}>
            <Intro>You&apos;ve got meds!</Intro>
            <h3 style={{ margin: "8px 0 16px" }}>
              You have a new prescription from Dr. {item.doctor.lastName}
            </h3>

            <Flex>
              <Icon src={icon} alt="medication icon" />
              <Details>
                <h4>{item.drugName}</h4>
                <Info>
                  {item.doseStrength} / {item.quantity}
                </Info>
              </Details>
              <Link to={`/my-clutch/${item.id}-${encodeURI(item.drugName)}`}>
                <Button size="small">Fill</Button>
              </Link>
            </Flex>

            <Link to="/my-clutch">
              <Button
                variation="ghost"
                style={{ margin: "15px auto 0", display: "block" }}
              >
                View All Prescriptions
              </Button>
            </Link>
          </Card>
        );
      })}

      {!newPrescriptions.length && (
        <EmptyPrescriptionCard>
          <p>No new updates.</p>
        </EmptyPrescriptionCard>
      )}
    </Wrapper>
  );
}
// styles
const EmptyPrescriptionCard = styled(Card)`
  display: none;

  @media (min-width: ${Theme.breakpoints.md}) {
    display: block;
  }

  p {
    color: ${Theme.textLight};
  }
`;
const Wrapper = styled.div`
  display: block;
  margin-bottom: 10px;

  @media (min-width: ${Theme.breakpoints.md}) {
    width: 336px;
    margin-right: 38px;
  }
`;
const Intro = styled.div`
  color: ${Theme.textMedium};
`;
const Icon = styled.img`
  width: 42px;
  margin-right: 24px;
`;
const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
const Details = styled.div`
  flex-grow: 1;
`;
const Info = styled.span`
  color: ${Theme.textLight};
`;
const Heading = styled.h4`
  margin-bottom: 19px;
  display: none;

  @media (min-width: ${Theme.breakpoints.md}) {
    display: block;
  }
`;
