import React from "react";
import t from "prop-types";
import { MenuItem, Select as MuiSelect, InputLabel } from "@material-ui/core";
import Theme from "../../styles/Theme";

Select.propTypes = {
  label: t.string.isRequired,
  name: t.string.isRequired,
  onChange: t.func.isRequired,
  options: t.arrayOf(
    t.shape({
      value: t.oneOfType([t.string, t.number, t.bool]).isRequired,
      label: t.string.isRequired
    })
  ).isRequired,
  hasError: t.bool,
  onBlur: t.func,
  placeholder: t.string,
  value: t.oneOfType([t.string, t.number, t.bool])
};

export default function Select({
  label,
  name,
  options,
  hasError,
  placeholder,
  ...props
}) {
  return (
    <>
      <InputLabel
        shrink={false}
        htmlFor={name}
        style={hasError ? { color: Theme.orange } : {}}
      >
        {label}
      </InputLabel>
      <MuiSelect name={name} displayEmpty={!!placeholder} {...props}>
        {placeholder && (
          <MenuItem value="">
            <span style={{ color: Theme.textLight, opacity: 0.75 }}>
              {placeholder}
            </span>
          </MenuItem>
        )}
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </>
  );
}
