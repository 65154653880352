import gql from "graphql-tag";
import { GET_FLASH_MESSAGES } from "../components/FlashNotifier";

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    hasGraphError: Boolean!
    currentGraphError: String!
    flashMessages: [Flash!]!
  }

  extend type Mutation {
    addOrRemoveFlash(
      id: ID
      message: StringOrReactComponent
      icon: ReactComponent
      color: String
    ): [Flash!]!
  }
`;

export const resolvers = {
  // fieldName: (parent, args, context, info) => data;
  Mutation: {
    addOrRemoveFlash: (_, { id, message, icon, color }, { cache }) => {
      const queryResult = cache.readQuery({
        query: GET_FLASH_MESSAGES
      });

      if (queryResult) {
        const { flashMessages } = queryResult;
        const data = {
          flashMessages: flashMessages.every(flash => flash.id !== id)
            ? [
                ...flashMessages,
                {
                  __typename: "Flash",
                  id: Math.random(),
                  message,
                  icon: icon || null,
                  color: color || null
                }
              ]
            : flashMessages.filter(flash => flash.id !== id)
        };

        cache.writeQuery({ query: GET_FLASH_MESSAGES, data });
        return data.flashMessages;
      }
      return [];
    }
  }
};
