import React from "react";
import styled from "styled-components";
import t from "prop-types";
import { titleCase } from "../utils/helpers";

FormattedAddress.propTypes = {
  street1: t.string.isRequired,
  street2: t.string,
  city: t.string.isRequired,
  state: t.string.isRequired,
  zipCode: t.string.isRequired,
  textColor: t.string,
  className: t.string
};

export default function FormattedAddress({
  street1,
  street2,
  city,
  state,
  zipCode,
  textColor,
  ...props
}) {
  return (
    <StyledSpan textColor={textColor} {...props}>
      {titleCase(street1)},
      {street2 && (
        <>
          <br />
          {titleCase(street2)},
        </>
      )}
      <br />
      {`${titleCase(city)}, ${state} ${zipCode}`}
    </StyledSpan>
  );
}

const StyledSpan = styled.span`
  display: inline-block;

  ${props => props.textColor && `color: ${props.textColor}`}
`;
