import React from "react";
import gql from "graphql-tag";
import t from "prop-types";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/Button";
import Error from "../components/Error";
import Fieldset from "../components/FormFields/Fieldset";
import Input from "../components/FormFields/Input";
import Loader from "../components/Loader";
import logo from "../static/images/clutch-logo.svg";
import MutationError from "../components/MutationError";
import {
  Wrapper,
  Header,
  Logo,
  Heading,
  Intro,
  Footer,
  LoggedOutPageCard,
  LoaderWrap
} from "./CreateAccountPage";
import { TOGGLE_FLASH } from "../components/FlashNotifier";
import { ReactComponent as Check } from "../static/icons/check.svg";

ForgotPasswordPage.propTypes = {
  history: t.object.isRequired
};

const RESET = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export default withRouter(ForgotPasswordPage);
function ForgotPasswordPage({ history }) {
  const [toggleFlash] = useMutation(TOGGLE_FLASH);
  const [resetPassword, { loading, error, client }] = useMutation(RESET, {
    onError() {
      // this callback prevents apollo from throwing
      // ...unhandled exception on 400 status code
    },
    onCompleted() {
      history.push("/login");
      toggleFlash({
        variables: {
          message: (
            <span>
              <strong>Password reset!</strong>
              <br />
              We sent a temporary password via sms message to the phone number
              we have on file for you.
            </span>
          ),
          icon: <Check />
        }
      });
      // this clears the global cached error "hack"
      client.writeData({ data: { hasGraphError: false } });
    }
  });

  return (
    <Wrapper>
      <Header>
        <Logo src={logo} alt="logo" />
      </Header>

      <LoggedOutPageCard>
        <Heading>Enter Email</Heading>
        <Intro>Enter your email below to receive a new password by SMS.</Intro>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={async values => resetPassword({ variables: values })}
          validationSchema={yup.object().shape({
            email: yup
              .string()
              .email("Must be a valid email")
              .required("Required")
          })}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Fieldset>
                  <div>
                    <Input
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.email && !!errors.email}
                    />
                    {touched.email && errors.email && (
                      <Error inputError>{errors.email}</Error>
                    )}
                  </div>
                </Fieldset>
                <Button type="submit" size="full" disabled={isSubmitting}>
                  Send Password Reset
                </Button>
              </form>
            );
          }}
        </Formik>

        {loading && (
          <LoaderWrap>
            <Loader />
          </LoaderWrap>
        )}
        {error && <MutationError error={error} />}
      </LoggedOutPageCard>

      <Footer>
        Don't have an account? <Link to="/create-account">Create Account</Link>
      </Footer>
    </Wrapper>
  );
}
