import React, { useState } from "react";
import t from "prop-types";
import styled from "styled-components";
import Theme from "../../styles/Theme";
import { useMediaQuery } from "@material-ui/core";
import Button from "../Button";
import FormattedAddress from "../FormattedAddress";
import { ReactComponent as Pin } from "../../static/icons/icon-pin.svg";
import { ReactComponent as Phone } from "../../static/icons/icon-phone.svg";

// prop types
PharmacyListItem.propTypes = {
  pharmacy: t.object.isRequired,
  setModalOpen: t.func
};

export default function PharmacyListItem({ pharmacy, setModalOpen }) {
  const isDesktop = useMediaQuery(`(min-width: ${Theme.breakpoints.md})`);
  const [showLocations, setShowLocations] = useState(false);

  return (
    <>
      {!isDesktop && (
        <>
          <Item key={pharmacy.npi}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4>{pharmacy.name}</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 6
                }}
              >
                <Distance style={{ marginRight: 12 }}>
                  <LocationPin />
                  <span> {pharmacy.locations[0].distance} mi</span>
                </Distance>
                <MoreLocations onClick={() => setShowLocations(!showLocations)}>
                  {showLocations ? "Less" : "More"} Locations
                </MoreLocations>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end"
              }}
            >
              <Price>${pharmacy.price}</Price>
              {!showLocations && (
                <Button
                  onClick={() =>
                    setModalOpen({
                      open: true,
                      pharmacy: {
                        ...pharmacy,
                        location: pharmacy.locations[0]
                      }
                    })
                  }
                  size="small"
                >
                  Fill Here
                </Button>
              )}
            </div>
          </Item>
          {showLocations && (
            <div>
              {pharmacy.locations.map(function(location, index) {
                return (
                  <Location key={index}>
                    <div>
                      <FormattedAddress {...location.address} />
                      <div style={{ display: "flex", marginTop: 4 }}>
                        <Distance>
                          <LocationPin />
                          <span> {location.distance} mi</span>
                        </Distance>
                        <PhoneLink href={`tel:${location.phone}`}>
                          <Phone
                            style={{
                              height: 15
                            }}
                          />{" "}
                          Call Pharmacy
                        </PhoneLink>
                      </div>
                    </div>
                    <Button
                      onClick={() =>
                        setModalOpen({
                          open: true,
                          pharmacy: {
                            ...pharmacy,
                            location: location
                          }
                        })
                      }
                      size="small"
                      style={{ height: 24 }}
                    >
                      Fill Here
                    </Button>
                  </Location>
                );
              })}
            </div>
          )}
        </>
      )}
      {isDesktop && (
        <>
          <Item key={pharmacy.npi}>
            <Title>
              <h4>{pharmacy.name}</h4>
              {!showLocations && (
                <FormattedAddress
                  {...pharmacy.locations[0].address}
                  textColor={Theme.textMedium}
                />
              )}
            </Title>
            {!showLocations && (
              <Distance>
                <LocationPin />
                <span> {pharmacy.locations[0].distance} mi</span>
              </Distance>
            )}
            <Price>${pharmacy.price}</Price>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {!showLocations && (
                <Button
                  onClick={() =>
                    setModalOpen({
                      open: true,
                      pharmacy: {
                        ...pharmacy,
                        location: pharmacy.locations[0]
                      }
                    })
                  }
                  size="small"
                  style={{ marginBottom: 18 }}
                >
                  Fill Here
                </Button>
              )}
              <MoreLocations onClick={() => setShowLocations(!showLocations)}>
                {showLocations ? "Less" : "More"} Locations
              </MoreLocations>
            </div>
          </Item>
          {showLocations && (
            <>
              {pharmacy.locations.map(function(location, index) {
                return (
                  <Location key={index}>
                    <div>
                      <FormattedAddress {...location.address} />
                      <div style={{ display: "flex", marginTop: 4 }}>
                        <Distance>
                          <LocationPin />
                          <span> {location.distance} mi</span>
                        </Distance>
                        <PhoneLink href={`tel:${location.phone}`}>
                          <Phone
                            style={{
                              height: 15
                            }}
                          />{" "}
                          Call Pharmacy
                        </PhoneLink>
                      </div>
                    </div>
                    <Button
                      onClick={() =>
                        setModalOpen({
                          open: true,
                          pharmacy: {
                            ...pharmacy,
                            location: location
                          }
                        })
                      }
                      size="small"
                      style={{ height: 24 }}
                    >
                      Fill Here
                    </Button>
                  </Location>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
}

// style
const Item = styled.div`
  padding: 17px 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const Title = styled.div`
  flex-grow: 1;
  max-width: 228px;
`;
const Distance = styled.div`
  flex-shrink: 0;
  color: ${Theme.textMedium};

  display: flex;
  align-items: center;

  span {
    ${Theme.smallText};
    line-height: 21px;
  }
`;
const PhoneLink = styled.a`
  flex-shrink: 0;
  margin-left: 10px;
  color: ${Theme.purple};
  font-size: 11px;
  line-height: 13px;

  display: flex;
  align-items: center;
`;
const Price = styled.span`
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  margin-bottom: 10px;

  @media (min-width: ${Theme.breakpoints.md}) {
    padding: 0 20px 0 10px;
  }
`;
const MoreLocations = styled.div`
  ${Theme.smallText};
  color: ${Theme.purple};
  cursor: pointer;
`;
const Location = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: ${Theme.windowBackground};
  border-bottom: 1px solid #ededed;
`;
const LocationPin = styled(Pin)`
  display: inline-block;
  height: 15px;
  margin-right: 2px;
`;
