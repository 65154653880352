import React from "react";
import get from "lodash.get";
import gql from "graphql-tag";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import Card from "../components/Card";
import EmptyTable from "../components/EmptyTable";
import Error from "../components/Error";
import Loader from "../components/Loader";
import PrescriptionList from "../components/MyClutch/PrescriptionList";
import PrescriptionListMobile from "../components/MyClutch/PrescriptionListMobile";
import SinglePrescription from "../components/MyClutch/SinglePrescription";
import styled from "styled-components";
import Theme, { FadeIn } from "../styles/Theme";
import { prescriptionFragment } from "../utils/gqlFragments";

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      prescriptions {
        ...PrescriptionFields
      }
    }
  }
  ${prescriptionFragment}
`;

export default function MyClutchPage() {
  // setup
  const { loading, error, data } = useQuery(CURRENT_USER);
  let { path } = useRouteMatch();
  const isDesktop = useMediaQuery(`(min-width: ${Theme.breakpoints.sm})`);
  const prescriptions = get(data, "currentUser.prescriptions", []);

  // render
  if (loading) return <Loader />;
  if (error) return <Error>{error.message}</Error>;

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <FadeIn>
            <h1>My Clutch</h1>
            {prescriptions.length ? (
              <>
                {isDesktop ? (
                  <StyledCard>
                    <PrescriptionList prescriptions={prescriptions} />
                  </StyledCard>
                ) : (
                  <PrescriptionListMobile prescriptions={prescriptions} />
                )}
              </>
            ) : (
              <EmptyTable
                title="Prescriptions"
                message="No prescriptions found."
              />
            )}
          </FadeIn>
        </Route>
        <Route path={`${path}/:prescriptionURI`}>
          <SinglePrescription prescriptions={prescriptions} />
        </Route>
      </Switch>
    </>
  );
}

// style
const StyledCard = styled(Card)`
  padding: 0;
  margin-top: 30px;
`;
