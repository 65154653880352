import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Theme from "../styles/Theme";

const StyledLoader = withStyles({
  root: {
    color: Theme.purple
  }
})(CircularProgress);

const StyledLoaderAlt = withStyles({
  root: {
    color: Theme.orange
  }
})(CircularProgress);

// prop types
Loader.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
  color: PropTypes.string
};

export default function Loader({
  size = 40,
  thickness = 5,
  color = "primary",
  ...props
}) {
  return (
    <>
      {color === "primary" && (
        <StyledLoader size={size} thickness={thickness} {...props} />
      )}
      {color === "alt" && (
        <StyledLoaderAlt size={size} thickness={thickness} {...props} />
      )}
    </>
  );
}
