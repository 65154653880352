import React from "react";
import t from "prop-types";
import Unfilled from "../static/images/icon-status-unfilled.svg";
import Sent from "../static/images/icon-status-sent.svg";
import Filled from "../static/images/icon-status-filled.svg";
import styled from "styled-components";
import Theme from "../styles/Theme";

// prop types
PrescriptionStatus.propTypes = {
  status: t.oneOf(["unprocessed", "transferred", "filled", "reversed"])
    .isRequired,
  text: t.string
};

export default function PrescriptionStatus({ status, text }) {
  return (
    <Wrapper status={status}>
      {status === "unprocessed" && (
        <>
          <Icon src={Unfilled} />
          <Label>{text ? text : "Unfilled"}</Label>
        </>
      )}
      {status === "transferred" && (
        <>
          <Icon src={Sent} />
          <Label>{text ? text : "Sent to Pharmacy"}</Label>
        </>
      )}
      {status === "filled" && (
        <>
          <Icon src={Filled} />
          <Label style={{ color: Theme.textNormal }}>
            {text ? text : "Filled"}
          </Label>
        </>
      )}
      {status === "reversed" && (
        <>
          <Icon src={Unfilled} />
          <Label style={{ color: Theme.textNormal }}>
            {text ? text : "Reversed"}
          </Label>
        </>
      )}
    </Wrapper>
  );
}

// style
const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 7px;
  }
`;
const Label = styled.span`
  ${Theme.smallText}
  color: ${Theme.orange};
`;
const Icon = styled.img`
  height: 14px;
`;
