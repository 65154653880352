import React from "react";
import styled from "styled-components";
import t from "prop-types";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "../Button";
import Error from "../Error";
import Input from "../FormFields/Input";
import Select from "../FormFields/Select";
import Theme from "../../styles/Theme";

ZipCodeSearch.propTypes = {
  geoSearch: t.func.isRequired,
  id: t.string.isRequired,
  loading: t.bool.isRequired
};

export default function ZipCodeSearch({ geoSearch, id, loading }) {
  return (
    <Formik
      initialValues={{ zipCode: "", radius: 5 }}
      onSubmit={async values => geoSearch({ variables: { id, ...values } })}
      validationSchema={yup.object().shape({
        zipCode: yup
          .string()
          .matches(
            /^[0-9]{5}(?:-[0-9]{4})?$/,
            "Must be a valid 5 or 9 digit zip code"
          )
      })}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        isSubmitting
      }) => (
        <StyledForm onSubmit={handleSubmit}>
          <ItemWrapper>
            <Input
              label="Zip Code"
              name="zipCode"
              placeholder="Enter zip code"
              value={values.zipCode}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched.zipCode && !!errors.zipCode}
            />
            {touched.zipCode && errors.zipCode && (
              <Error inputError>{errors.zipCode}</Error>
            )}
          </ItemWrapper>

          <ItemWrapper>
            <Select
              name="radius"
              label="Radius"
              placeholder="Enter distance"
              options={[
                { value: 5, label: "Within 5 miles" },
                { value: 10, label: "Within 10 miles" },
                { value: 15, label: "Within 15 miles" },
                { value: 20, label: "Within 20 miles" },
                { value: 25, label: "Within 25 miles" }
              ]}
              value={values.radius}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched.radius && !!errors.radius}
            />
            {errors.radius && touched.radius && (
              <Error inputError>{errors.radius}</Error>
            )}
          </ItemWrapper>

          <Button
            type="submit"
            size="small"
            disabled={
              isSubmitting ||
              loading ||
              Object.values(values).some(value => !value)
            }
          >
            Search Pharmacies
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
}

// style
const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin: 32px 0 -14px;

  button {
    height: 42px;
    width: 100%;
    margin: 20px auto 0;
  }

  @media (min-width: ${Theme.breakpoints.sm}) {
    flex-wrap: nowrap;
    button {
      width: auto;
      margin: 0 0 0 auto;
    }
  }
`;
const ItemWrapper = styled.div`
  flex-grow: 1;

  &:first-of-type {
    padding-right: 12px;
  }

  @media (min-width: ${Theme.breakpoints.sm}) {
    flex-grow: 0;
    padding-right: 12px;
  }
`;
