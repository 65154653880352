import styled, { css } from "styled-components";
import Theme from "../styles/Theme";

const inputErrorStyles = css`
  margin: 5px 0 0;
  font-size: 12px;
  line-height: 16px;
`;
const Error = styled.p`
  color: ${Theme.orange};
  ${props => props.inputError && inputErrorStyles}
`;
export default Error;
