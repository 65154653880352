import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Theme from "../../styles/Theme";
import Card from "../Card";
import Modal from "../Modal";
import IconPlay from "../../static/images/icon-play-circle.svg";

// prop types
VideoCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  bgImage: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired
};

export default function VideoCard({ title, subtitle, bgImage, videoUrl }) {
  const [openModal, setOpenModal] = React.useState(false);
  const toggleOpen = () => setOpenModal(!openModal);

  return (
    <>
      <StyledCard onClick={toggleOpen}>
        <MediaWrapper>
          <Media image={bgImage} />
          <img src={IconPlay} alt="play" />
        </MediaWrapper>
        <TextWrapper>
          <Subtitle>{subtitle}</Subtitle>
          <Title>{title}</Title>
        </TextWrapper>
      </StyledCard>
      {openModal && (
        <Modal title={title} onClose={toggleOpen}>
          <VideoWrapper>
            <iframe
              title={title}
              width="560"
              height="349"
              src={videoUrl}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </VideoWrapper>
        </Modal>
      )}
    </>
  );
}

// style
export const StyledCard = styled(Card)`
  overflow: hidden;
  border-radius: 14px;
  padding: 0;
`;
const MediaWrapper = styled.div`
  position: relative;
  height: 240px;
  width: 100%;
  cursor: pointer;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
  }
`;
const Media = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`;
const TextWrapper = styled.div`
  padding: 16px 14px;
`;
const Subtitle = styled.span`
  color: ${Theme.textMedium};
  font-size: 1.6rem;
  line-height: 2.1rem;
`;
const Title = styled.h4`
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin: 4px 0 0;
  cursor: pointer;
`;
const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: 100vw;
  max-width: 720px;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.25) 9.5%,
    transparent 21%
  );
  background-color: #2e1953;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
