import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import bgRegular from "../static/images/pathways-bg.png";
import bgRetina from "../static/images/pathways-bg@2x.png";
import logo from "../static/images/clutch-logo.svg";
import Card from "../components/Card";
import FindAccount from "../components/CreateAccount/FindAccount";
import CreateUser from "../components/CreateAccount/CreateUser";
import VerifyPhone from "../components/CreateAccount/VerifyPhone";
import Theme, { fadeInStyles } from "../styles/Theme";

export default function CreateAccountPage() {
  const [state, setState] = useState({
    uiStep: "findAccount", // findAccount | createUser | verifyPhone
    userDetails: {
      dateOfBirth: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      phone: "",
      registrationCode: "",
      sex: ""
    }
  });

  return (
    <Wrapper>
      <Header>
        <Logo src={logo} alt="logo" />
      </Header>

      {state.uiStep === "findAccount" && (
        <FindAccount state={state} setState={setState} />
      )}
      {state.uiStep === "createUser" && (
        <CreateUser state={state} setState={setState} />
      )}
      {state.uiStep === "verifyPhone" && (
        <VerifyPhone state={state} setState={setState} />
      )}

      <Footer>
        Already have an account? <Link to="/login">Login</Link>
      </Footer>
    </Wrapper>
  );
}

// style
export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${bgRegular}) no-repeat center;
  background-size: cover;

  @media (min-resolution: 192dpi) {
    background-image: url(${bgRetina});
  }

  .MuiFormLabel-root {
    font-size: 12px;
    color: ${Theme.purple};
    font-weight: 500;
    letter-spacing: 1px;
  }
  .MuiInputBase-input {
    font-size: 16px;
    line-height: 19px;
  }
`;
export const Header = styled.header`
  font-size: calc(10px + 2vmin);
  padding: 34px 0;
`;
export const Logo = styled.img`
  width: 122px;
  pointer-events: none;
`;
export const Heading = styled.h4`
  margin: 4px 0px;
  font-size: 20px;
  line-height: 24px;
`;
export const Intro = styled.div`
  font-size: 14px;
  line-height: 19px;
  margin: 6px 0 18px;
  color: ${Theme.textMedium};
`;
export const Footer = styled.div`
  margin-top: 2.7rem;
  color: ${Theme.textLight};
  font-size: 16px;
  line-height: 21px;

  a {
    color: ${Theme.orange};
    &:hover,
    &:focus {
      color: ${Theme.orangeHover};
    }
  }
`;
export const LoggedOutPageCard = styled(Card)`
  max-width: 327px;
  ${fadeInStyles}
`;
export const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
