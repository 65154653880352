import React from "react";
import t from "prop-types";
import styled from "styled-components";
import Theme from "../../styles/Theme";
import { ReactComponent as Pin } from "../../static/icons/icon-pin.svg";
import { ReactComponent as Phone } from "../../static/icons/icon-phone.svg";
import Card from "../Card";
import FormattedAddress from "../FormattedAddress";
import Button from "../Button";

// prop types
PharmacyCard.propTypes = {
  pharmacy: t.shape({
    id: t.string.isRequired,
    address: t.shape({
      city: t.string.isRequired,
      state: t.string.isRequired,
      street1: t.string.isRequired,
      street2: t.string,
      zipCode: t.string.isRequired,
    }).isRequired,
    pharmacy: t.string.isRequired,
    phoneNumber: t.string.isRequired,
    price: t.string,
  }),
};

export default function PharmacyCard({ pharmacy }) {
  const encodedAddress = encodeURIComponent(
    `${pharmacy.address.street1}, ${pharmacy.address.city}, ${pharmacy.address.state} ${pharmacy.address.zipCode}`
  );

  return (
    <>
      <StyledCard>
        <Row style={{ padding: "24px 16px" }}>
          <h3 style={{ marginBottom: 0 }}>{pharmacy.pharmacy}</h3>
          <Price>{pharmacy.price}</Price>
        </Row>
        <Row style={{ background: "#F5F5F5" }}>
          <FormattedAddress {...pharmacy.address} />
          <Buttons>
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ display: "flex" }}>
                <Pin style={{ fill: "#fff", height: 20 }} />
                Directions
              </Button>
            </a>
            <a href={`tel:${pharmacy.phoneNumber}`}>
              <Button style={{ display: "flex" }}>
                <Phone style={{ fill: "#fff", height: 20, marginRight: 4 }} />
                Call
              </Button>
            </a>
          </Buttons>
        </Row>
      </StyledCard>
    </>
  );
}

// style
const StyledCard = styled(Card)`
  padding: 0;
  width: 100%;

  @media (min-width: ${Theme.breakpoints.md}) {
    margin-top: 33px;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  flex-wrap: wrap;
`;
const Price = styled.span`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #281a4c;
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    width: 148px;
    margin-left: 16px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    margin-top: 15px;

    > button {
      margin: 0 8px;
    }
  }
`;
