import { useState, useEffect } from "react";

export function useGeolocation() {
  const [initializing, setInitializing] = useState(true);
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  const onSuccess = ({ coords }) => {
    setInitializing(false);
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error) => {
    setInitializing(false);
    setError(error.message);
  };

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError("Geolocation is not supported");
      return;
    }

    geo.getCurrentPosition(onSuccess, onError);
  }, []);

  return { ...position, error, initializing };
}
