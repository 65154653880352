import React from "react";
import styled from "styled-components";
import t from "prop-types";
import Theme from "../../styles/Theme";
import { ReactComponent as CustomCheckbox } from "../../static/images/checkbox.svg";

Checkbox.propTypes = {
  onChange: t.func.isRequired,
  label: t.string.isRequired
};

export default function Checkbox({ onChange, label }) {
  return (
    <Label>
      <input type="checkbox" onChange={onChange} />
      <CustomCheckbox />
      <span>{label}</span>
    </Label>
  );
}

// style
const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
  }

  svg {
    margin: 0 8px 1px 0;

    .box {
      transition: ${Theme.transition};
      fill: ${Theme.uiWhite};
      stroke: ${Theme.uiLighter};
    }
    .mark {
      transition: transform 200ms ${Theme.pop} 100ms, opacity 150ms ease-in-out 0ms;
      transform-origin: center;
      transform: scale(0.33);
    }
  }
  input:checked + svg {
    .box {
      fill: ${Theme.orange};
      stroke: ${Theme.orange};
    }

    .mark {
      transform: scale(1);
    }
  }
`;
