import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Switch as MuiSwitch } from "@material-ui/core";
import Theme from "../../styles/Theme";

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.bool
};

const StyledSwitch = withStyles(theme => ({
  root: {
    width: 35,
    height: 22,
    padding: 0,
    margin: "0 8px",
    overflow: "visible"
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        background: Theme.gradients.purple,
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: Theme.purple,
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)"
  },
  track: {
    borderRadius: 22 / 2,
    border: `1px solid ${Theme.uiLighter}`,
    backgroundColor: Theme.uiWhite,
    opacity: 1
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <MuiSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export default function Switch({ value, onChange, ...props }) {
  return <StyledSwitch value={value} onChange={onChange} {...props} />;
}
