import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import t from "prop-types";
import Theme, { fadeInStyles, fadeOutStyles } from "../styles/Theme";

// prop types
Snackbar.propTypes = {
  message: t.oneOfType([t.string, t.element]).isRequired,
  onClose: t.func.isRequired,
  color: t.string,
  icon: t.element
};

export default function Snackbar({ message, onClose, icon, color }) {
  const [leaving, setLeaving] = useState(false);
  const [counter, setCounter] = useState(6);

  useEffect(() => {
    let unmount = setInterval(() => {
      setCounter(counter - 1);
      if (counter === 2) setLeaving(true);
      if (counter === 1) onClose();
    }, 1000);

    return () => {
      clearInterval(unmount);
    };
  }, [onClose, counter]);

  return (
    <>
      {createPortal(
        <Wrapper
          onClick={() => setLeaving(true)}
          color={color}
          leaving={leaving}
        >
          {icon && icon}
          <Message>{message}</Message>
        </Wrapper>,
        document.body
      )}
    </>
  );
}

// styles
const Wrapper = styled.div`
  ${fadeInStyles};
  ${props => !!props.leaving && fadeOutStyles}
  position: fixed;
  top: 20px;
  right: 20px;
  width: 100%;
  max-width: 300px;
  background: ${Theme.orange};
  color: ${Theme.uiWhite};
  cursor: pointer;
  padding: 13px 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  ${props => props.color && `background: ${Theme[props.color]};`}

  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
`;
const Message = styled.span`
  font-size: 14px;
  line-height: 19px;
`;
