import React from "react";
import gql from "graphql-tag";
import omit from "lodash.omit";
import styled from "styled-components";
import t from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import Button from "../Button";
import Card from "../Card";
import FormattedAddress from "../FormattedAddress";
import Loader from "../Loader";
import Modal from "../Modal";
import MutationError from "../MutationError";
import Theme from "../../styles/Theme";
import { CURRENT_USER } from "../../pages/MyClutchPage";
import { TOGGLE_FLASH } from "../FlashNotifier";
import { prescriptionFragment } from "../../utils/gqlFragments";
import { ReactComponent as Sent } from "../../static/icons/prescription-sent.svg";
import { ReactComponent as CloseIcon } from "../../static/icons/icon-close.svg";
import { ReactComponent as Pin } from "../../static/icons/icon-pin.svg";

// prop types
PharmacyModal.propTypes = {
  isGeneric: t.bool.isRequired,
  pharmacy: t.object.isRequired,
  prescription: t.shape({
    id: t.string.isRequired,
    doseForm: t.string,
    doseStrength: t.string,
    quantity: t.number.isRequired,
    drugName: t.string.isRequired,
    genericDrugName: t.string,
    new: t.bool,
    state: t.oneOf(["unprocessed", "transferred", "filled", "reversed"]),
    instructions: t.string.isRequired,
    refillsRemaining: t.number.isRequired,
    doctor: t.shape({
      id: t.string.isRequired,
      firstName: t.string.isRequired,
      lastName: t.string.isRequired,
      phone: t.string
    }).isRequired
  }).isRequired,
  onClose: t.func.isRequired
};

const FILL = gql`
  mutation RequestFill(
    $prescriptionId: ID!
    $pharmacy: PharmacyInput!
    $genericEquivalent: Boolean!
  ) {
    sendOrderToClutchRep(
      prescriptionId: $prescriptionId
      pharmacy: $pharmacy
      genericEquivalent: $genericEquivalent
    ) {
      ...PrescriptionFields
    }
  }
  ${prescriptionFragment}
`;

export default function PharmacyModal({
  isGeneric,
  prescription,
  pharmacy,
  onClose
}) {
  const [toggleFlash] = useMutation(TOGGLE_FLASH);

  const [fill, { loading, error, client }] = useMutation(FILL, {
    onError() {
      // this callback prevents apollo from throwing
      // ...unhandled exception on 400 status code
    },
    onCompleted() {
      onClose();
      toggleFlash({
        variables: {
          message: (
            <>
              <strong>Success! </strong>
              Your prescription has been sent to the pharmacy
            </>
          ),
          icon: <Sent />,
          color: "purple"
        }
      });
      client.writeData({
        // this clears the global cached error "hack"
        hasGraphError: false
      });
    },
    update(cache, { data: { sendOrderToClutchRep: mutatedPrescription } }) {
      const { currentUser } = cache.readQuery({ query: CURRENT_USER });
      currentUser.prescriptions[
        currentUser.prescriptions.findIndex(
          prescription => prescription.id === mutatedPrescription.id
        )
      ] = mutatedPrescription;

      cache.writeQuery({
        query: CURRENT_USER,
        data: { currentUser }
      });
    }
  });

  const handleFill = () => {
    const pharmacyInput = {
      npi: pharmacy.location.npi,
      pharmacy: pharmacy.name,
      phoneNumber: pharmacy.location.phone,
      price: pharmacy.price,
      address: {
        ...omit(pharmacy.location.address, ["id", "__typename"])
      }
    };
    fill({
      variables: {
        prescriptionId: prescription.id,
        pharmacy: pharmacyInput,
        genericEquivalent: isGeneric
      }
    });
  };

  return (
    <StyledModal onClose={onClose}>
      <Card>
        <ModalWrapper>
          <SpreadRow>
            <Heading>{prescription.drugName} Prescription</Heading>
            <Close onClick={onClose}>
              <CloseIcon />
            </Close>
          </SpreadRow>
          <SpreadRow style={{ margin: "20px 0 6px 0" }}>
            <Name>{pharmacy.name}</Name>
            <Price>${pharmacy.price}</Price>
          </SpreadRow>
          <SpreadRow>
            <FormattedAddress {...pharmacy.location.address} />
            <Note>Estimated price with coupon</Note>
          </SpreadRow>
          <Distance>
            <LocationPin />
            <span> {pharmacy.location.distance} mi</span>
          </Distance>
          <Buttons>
            {loading && <Loader />}
            {!loading && (
              <>
                <Button size="full" onClick={handleFill}>
                  Send to Pharmacy
                </Button>
                <Button size="full" variation="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </>
            )}
          </Buttons>
          {error && <MutationError inputError error={error} />}
        </ModalWrapper>
      </Card>
    </StyledModal>
  );
}

// style
const StyledModal = styled(Modal)`
  width: 100%;
`;
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 10px 0;
`;
const SpreadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Close = styled.span`
  color: ${Theme.purple};
  cursor: pointer;
  margin-top: 2px;
  margin-left: 12px;
`;
const Heading = styled.h4`
  color: ${Theme.purple};
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
`;
const Distance = styled.div`
  margin-top: 10px;
  color: ${Theme.textMedium};
  display: flex;
  align-items: center;

  span {
    ${Theme.smallText};
  }
`;
const Price = styled.span`
  font-size: 20px;
  padding-left: 10px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${Theme.purple};
`;
const Name = styled.h3`
  color: ${Theme.purple};
`;
const Note = styled.span`
  max-width: 100px;
  color: ${Theme.textMedium};
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.75px;
  font-family: ${Theme.simplonNorm};
  text-align: right;
`;
const LocationPin = styled(Pin)`
  height: 15px;
  margin-right: 2px;
`;
const Buttons = styled.div`
  flex-direction: column;
  margin-top: 20px;
  align-items: center;

  button:last-of-type {
    margin-top: 6px;
  }
`;
