import React from "react";
import gql from "graphql-tag";
import isEmpty from "lodash.isempty";
import { Formik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import * as yup from "yup";
import Button from "../components/Button";
import Error from "../components/Error";
import Fieldset from "../components/FormFields/Fieldset";
import Loader from "../components/Loader";
import logo from "../static/images/clutch-logo.svg";
import MutationError from "../components/MutationError";
import PasswordField from "../components/FormFields/PasswordField";
import PasswordConstraints, {
  passwordSchema
} from "../components/FormFields/PasswordConstraints";
import { TOGGLE_FLASH } from "../components/FlashNotifier";
import { ReactComponent as Check } from "../static/icons/check.svg";
import {
  Header,
  Heading,
  Intro,
  LoaderWrap,
  LoggedOutPageCard,
  Logo,
  Wrapper
} from "./CreateAccountPage";

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($password: String!) {
    updatePassword(password: $password) {
      id
      flags {
        mustUpdatePassword
      }
    }
  }
`;

export default function MustUpdatePasswordPage() {
  const [toggleFlash] = useMutation(TOGGLE_FLASH);

  const [updatePassword, { loading, error, client }] = useMutation(
    UPDATE_PASSWORD,
    {
      onError() {
        // this callback prevents apollo from throwing
        // ...unhandled exception on 400 status code
      },
      onCompleted() {
        toggleFlash({
          variables: {
            message: "Password Updated!",
            icon: <Check />
          }
        });
        // this callback clears the global cached error "hack"
        client.writeData({
          data: {
            hasGraphError: false
          }
        });
      }
    }
  );

  return (
    <Wrapper>
      <Header>
        <Logo src={logo} alt="logo" />
      </Header>

      <LoggedOutPageCard>
        <Heading>Reset password</Heading>
        <Intro>
          You've logged in with a temporary password. Before continuing, please
          set a new password below.
        </Intro>

        <Formik
          initialValues={{ password: "" }}
          onSubmit={async values => updatePassword({ variables: values })}
          validationSchema={yup.object().shape({
            password: passwordSchema.required("Required")
          })}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Fieldset>
                  <div>
                    <PasswordField
                      name="password"
                      label="New Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={touched.password && !!errors.password}
                    />
                    {errors.password && touched.password && (
                      <Error inputError>{errors.password}</Error>
                    )}
                    <PasswordConstraints value={values.password} />
                  </div>
                </Fieldset>
                <Button
                  type="submit"
                  size="full"
                  disabled={
                    isSubmitting ||
                    !isEmpty(errors) ||
                    Object.values(values).some(value => !value)
                  }
                >
                  Update Password
                </Button>
              </form>
            );
          }}
        </Formik>

        {loading && (
          <LoaderWrap>
            <Loader />
          </LoaderWrap>
        )}
        {error && <MutationError error={error} />}
      </LoggedOutPageCard>
    </Wrapper>
  );
}
